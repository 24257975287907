import { Character } from '@texas/components/article/variant/general/types';
import { httpClient } from '../httpClient/httpClient';

export interface CompositionData {
  id: number;
  text: string;
  value?: string;
}

export interface PantoneColor {
  id: number;
  pantoneCode: string;
  pantoneName: string;
  colorDescription: string;
}

interface DimensionValue {
  id: number;
  label: string;
  value: string;
}

export interface Dimension {
  id: number;
  dimensions: DimensionValue[];
}

export interface Composition {
  materials: CompositionMaterial[];
  weight: number;
  id: number;
  dimension: Dimension | null;
  archived: string | null;
  character: Character;
}

export interface CompositionMaterial {
  id: number;
  material: CompositionData;
  qualities: CompositionData[];
  techniques: CompositionData[];
  treatments: CompositionData[];
  colors: PantoneColor[];
  weight: number;
  note: string;
}

export interface QualityValueData {
  id: number;
  value: string;
}
export interface ColorData {
  pantoneCode: string;
  pantoneName: string;
  colorDescription: string;
}
export interface CreateCompositionMaterialRequest {
  materialId: number | null;
  qualities: QualityValueData[];
  techniqueIds: number[] | null | undefined;
  treatmentIds: number[] | null | undefined;
  colors: ColorData[];
  weight: number;
  note: string;
}
export type UpdateCompositionMaterialRequest = CreateCompositionMaterialRequest;

export interface UpdateCompositionRequest {
  weight: number;
  dimensions: DimensionData[];
  character: Character;
}

export interface DimensionData {
  id: number;
  value: string;
}

export interface CompositionCell {
  x: number;
  y: number;
  id: number;
  colorCodes: string[];
}

interface CopyCompositionRequest {
  compositionId: number;
  compositionGroupId: number;
}

export interface SupplierCompositionData {
  variantId: number;
  supplierId: number;
  branchId: number;
  cellX?: number;
  cellY?: number;
}

export const compositionApi = {
  getComposition: (compositionId: number) =>
    httpClient.get<Composition>(`compositions/${compositionId}`),
  addComposition: (compositionGroupId: number) =>
    httpClient.post<Composition>(`compositions/${compositionGroupId}`),
  updateComposition: (
    compositionId: number,
    request: UpdateCompositionRequest,
  ) =>
    httpClient.put<Composition>(`compositions/${compositionId}`, {
      data: request,
    }),
  removeComposition: (compositionId: number) =>
    httpClient.delete(`compositions/${compositionId}`),
  createCompositionMaterial: (
    compositionId: number,
    request: CreateCompositionMaterialRequest,
  ) =>
    httpClient.post<CompositionMaterial>(
      `compositions/${compositionId}/material`,
      { data: request },
    ),
  updateCompositionMaterial: (
    compositionId: number,
    request: UpdateCompositionMaterialRequest,
  ) =>
    httpClient.put<CompositionMaterial>(
      `compositions/${compositionId}/material`,
      { data: request },
    ),
  getCompositionMaterial: (materialCompositionId: number) =>
    httpClient.get<CompositionMaterial>(
      `compositions/${materialCompositionId}/material`,
    ),
  removeCompositionMaterial: (materialCompositionId: number) =>
    httpClient.delete(`compositions/${materialCompositionId}/material`),
  copyComposition: (fromId: number, request: CopyCompositionRequest) =>
    httpClient.post<Composition>(`compositions/${fromId}/copy`, {
      data: request,
    }),
  archiveComposition: (id: number) =>
    httpClient.put<Composition>(`compositions/${id}/archive`),
  restoreComposition: (id: number) =>
    httpClient.put<Composition>(`compositions/${id}/restore`),
};
