import { Text, Spinner, Checkbox, Flex, Button } from '@chakra-ui/react';
import { useEffect } from 'react';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useTranslation } from 'react-i18next';
import { useProdutApprovalWizardState } from '../../../context';

export function SelectBranches({ page }: { page: string }) {
  const { t } = useTranslation();
  const {
    data: branches,
    refetch,
    loading,
  } = useApiResource(variantsApi.getArticleBranches);

  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();

  useEffect(() => {
    if (data.selectedBranches.length > 0) {
      setStepIsValid(page, true);
      return;
    }

    setStepIsValid(page, false);
  }, [data.selectedBranches.length, page, setStepIsValid]);

  useEffect(() => {
    refetch(data.variantId);
  }, [refetch, data.variantId]);
  return (
    <>
      {loading && (
        <Flex gap={2} alignItems="center">
          <Text>{t('general.loading')}</Text>
          <Spinner size="sm" />
        </Flex>
      )}
      <Flex flexDir="column">
        {!loading && (
          <Button
            mx={4}
            width="fit-content"
            size="sm"
            variant="texas-outline-light"
            mb={2}
            onClick={() =>
              setData((d) => ({
                ...d,
                selectedBranches:
                  branches?.map((b) => ({
                    id: b.branchId,
                    name: b.branchName,
                    identifier: b.identifier,
                  })) ?? [],
              }))
            }
          >
            {t('general.selectAll')}
          </Button>
        )}
        {branches?.map((x) => {
          const toggled = data.selectedBranches.some(
            (b) => b.id === x.branchId,
          );
          return (
            <Flex
              px={4}
              py={2}
              {...(!x.blockedFromSync
                ? {
                    cursor: 'pointer',
                    backgroundColor: toggled ? 'gray.600' : 'transparent',
                    _hover: {
                      backgroundColor: 'gray.600',
                      opacity: toggled ? 1 : 0.7,
                    },
                    _light: {
                      backgroundColor: toggled ? 'gray.100' : 'transparent',
                      _hover: {
                        backgroundColor: 'gray.100',
                        opacity: toggled ? 1 : 0.7,
                      },
                    },
                  }
                : null)}
              transition="all 200ms ease"
              onClick={(e) => {
                if (x.blockedFromSync) return;
                e.preventDefault();

                if (!toggled) {
                  setData((d) => ({
                    ...d,
                    selectedBranches: [
                      ...d.selectedBranches,
                      {
                        id: x.branchId,
                        name: x.branchName,
                        identifier: x.identifier,
                      },
                    ],
                  }));
                  return;
                }

                setData((d) => ({
                  ...d,
                  selectedBranches: [
                    ...d.selectedBranches.filter((b2) => b2.id !== x.branchId),
                  ],
                  selectedSuppliers: [
                    ...d.selectedSuppliers.filter(
                      (s) => s.branch.id !== x.branchId,
                    ),
                  ],
                }));
              }}
              opacity={x.blockedFromSync || !toggled ? 0.4 : 1}
              key={x.branchId}
            >
              <Text fontWeight="medium">{x.branchName}</Text>
              <Checkbox
                marginInlineStart="auto !important"
                isChecked={toggled}
              />
            </Flex>
          );
        })}
      </Flex>
    </>
  );
}
