import { useApiResource } from '@texas/api/hooks/useApiResource';
import { BodyOverride } from '@texas/components/shared/BodyOverride';
import { useEffect, useState } from 'react';
import { Card, CardBody, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { NewSupplierCell } from './AddSupplierCell';
import {
  TexasDrawer,
  useTexasDrawer,
} from '@texas/components/shared/drawer/TexasDrawer';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { AddSupplier } from './add/AddSupplier';
import {
  VariantBranchSupplier,
  variantsApi,
} from '@texas/api/endpoints/variantsApi';
import { SupplierCell } from './SupplierCell';
import React from 'react';
import { RemoveSupplierDialog } from './RemoveSupplierDialog';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import gridCellStyle from '@texas/resources/theme/styles/gridCell';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';
import { useTranslation } from 'react-i18next';
import { TexasDrawerContent } from '@texas/components/shared/drawer/TexasDrawerContent';

export function VariantSuppliers({ variantId }: { variantId: number }) {
  const { t } = useTranslation();
  const {
    data: suppliersOverview,
    refetch,
    loading,
  } = useApiResource(variantsApi.getVariantBranchSuppliers);

  useEffect(() => {
    refetch(variantId);
  }, [variantId, refetch]);

  const [addSupplierData, setAddSupplierData] = useState<{
    branchId: number;
    branchName: string;
  } | null>(null);

  const drawer = useTexasDrawer(ModalState.Closed);

  const {
    value: removeSupplierValue,
    isOpen: isRemoveSupplierOpen,
    onOpen: onRemoveSupplierOpen,
    onClose: onRemoveSupplierClose,
  } = useValueDisclosure<VariantBranchSupplier>();

  return (
    <BodyOverride>
      {removeSupplierValue && (
        <RemoveSupplierDialog
          onRemove={() => refetch(variantId)}
          isOpen={isRemoveSupplierOpen}
          onClose={onRemoveSupplierClose}
          supplier={removeSupplierValue}
        />
      )}

      <TexasDrawer ref={drawer.ref} modal={drawer}>
        <TexasDrawerContent>
          {addSupplierData && (
            <AddSupplier
              variantId={variantId}
              branchId={addSupplierData.branchId}
              branchName={addSupplierData.branchName}
              onAdd={() => {
                refetch(variantId);
              }}
            />
          )}
        </TexasDrawerContent>
      </TexasDrawer>

      <Card variant="dark-border" overflow="scroll">
        <CardBody>
          {loading && (
            <LoadingOverlayV2 loaded={!loading} label={t('general.loading')} />
          )}
          <Grid autoFlow="row" autoColumns="240px" gap={4}>
            {suppliersOverview?.branches.map((b, i) => {
              const colIndex = i + 1;
              return (
                <React.Fragment key={b.id}>
                  <GridItem
                    colStart={colIndex}
                    rowStart={1}
                    key={b.id}
                    pos="sticky"
                    top={0}
                    bg="texas.bg.dark"
                    _light={{ bg: 'white' }}
                  >
                    <Flex flexDir="column" mb={2}>
                      <Text variant="header">{b.identifier}</Text>
                      <Text variant="sub" pb={2}>
                        {b.name}
                      </Text>
                      <NewSupplierCell
                        onClick={() => {
                          setAddSupplierData({
                            branchId: b.id,
                            branchName: b.name,
                          });
                          drawer.setModalState(ModalState.Open);
                        }}
                      />
                    </Flex>
                  </GridItem>

                  {suppliersOverview.suppliers
                    .filter((x) => x.branchId === b.id)
                    .map((x, rowIndex) => {
                      return (
                        <GridItem
                          {...gridCellStyle}
                          rowStart={2 + rowIndex + 1}
                          colStart={colIndex}
                          key={x.supplierId}
                        >
                          <SupplierCell
                            supplier={x}
                            onRemove={() => {
                              onRemoveSupplierOpen(x);
                            }}
                          />
                        </GridItem>
                      );
                    })}
                </React.Fragment>
              );
            })}
          </Grid>
        </CardBody>
      </Card>
    </BodyOverride>
  );
}
