import { Box, BoxProps, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Icons } from '../shared/Icons';
import { TexasHeader } from '../shared/TexasHeader';
import { useTranslation } from 'react-i18next';

export type WidgetStyle = 'default' | 'borderless';

interface WidgetWrapperProps {
  children: React.ReactNode;
  toolbox?: React.ReactNode;
  style: WidgetStyle;
  header: string;
}

export function WidgetWrapper({
  children,
  style = 'default',
  toolbox,
  header,
}: WidgetWrapperProps) {
  const widgetStyle = GetWidgetStyle(style);
  const { t } = useTranslation();
  return (
    <Flex height="100%" flexDirection="column">
      <TexasHeader text={header} />
      <Flex
        borderRadius={8}
        {...widgetStyle.widget}
        height="100%"
        minHeight={0}
      >
        <Flex
          className="header"
          {...widgetStyle.header}
          justifyContent="start"
          flexDir="column"
          px={2}
        >
          <Tooltip label={t('widgets.dragToMove')}>
            <IconButton
              size="xs"
              variant="unstyled"
              aria-label="Widget settings"
              icon={<Icons.DotsHorizontal boxSize="6" />}
            />
          </Tooltip>

          {toolbox}
        </Flex>
        <Box width="100%" minH={0} padding={2} minW={0}>
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}

function GetWidgetStyle(style: WidgetStyle): {
  widget: BoxProps;
  header: BoxProps;
} {
  switch (style) {
    default:
    case 'default': {
      return {
        widget: {
          backgroundColor: 'texas.bg.900',
          _light: { backgroundColor: 'white' },
        },
        header: {
          backgroundColor: 'texas.bg.800',
          borderRadius: '8',
          _light: { backgroundColor: 'gray.100' },
        },
      };
    }
    case 'borderless': {
      return {
        widget: {
          border: '1px solid',
          borderColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
          },
        },
        header: {
          border: '1px solid',
          borderRadius: '6',
          borderColor: 'texas.bg.800',
          backgroundColor: 'texas.bg.800',
          _light: {
            borderColor: 'gray.100',
            backgroundColor: 'gray.100',
          },
        },
      };
    }
  }
}
