import { Button, useDisclosure } from '@chakra-ui/react';
import { ProductApprovalModal } from './ProductApprovalModal';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { useTranslation } from 'react-i18next';
import { DemoComponent } from '@texas/components/shared/DemoComponent';

export function CreateProductApprovalButton({
  variantId,
  customerId,
  articleNodeGroups,
}: {
  variantId: number;
  customerId: number;
  articleNodeGroups: ArticleNodeGroup;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <DemoComponent>
      <Button variant="texas" onClick={onOpen}>
        {t('productApproval.productApproval')}
        <ProductApprovalModal
          customerId={customerId}
          variantId={variantId}
          articleNodeGroups={articleNodeGroups}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Button>
    </DemoComponent>
  );
}
