import {
  Flex,
  Image,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
} from '@chakra-ui/react';
import {
  Composition,
  CompositionData,
  CompositionMaterial,
} from '@texas/api/endpoints/compositionApi';
import placeholderImg from '@assets/placeholder-250.png';
import { GetColorFromCode } from '../../pantonePicker/PantonePicker';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from '../ColorDot';
import { ColorBox } from '../ColorBox';
import useFormat from '@texas/hooks/useFormat';
import { Icons } from '@texas/components/shared/Icons';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { Character, getCharacterLabel } from '../../../general/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';

export function CompositionView({
  composition,
  imageIdentifier,
  archiveDisabled,
}: {
  composition: Composition;
  imageIdentifier: string;
  archiveDisabled: boolean;
  onRestore?: (data: Composition) => void;
}) {
  const { t } = useTranslation();
  const { formatWeight } = useFormat();

  return (
    <Flex flexDir="column" gap={2} animation={fadeInLeftAnimation()}>
      {composition.archived && !archiveDisabled && (
        <Flex justify="center" gap={2} mt={2}>
          <Tag
            color="texas.sand.100"
            _light={{ color: 'texas.burntSienna.500' }}
          >
            <Icons.AlertCircle mr={1} />
            {`${t('general.archived')} ${formatDate(
              Locale.En,
              composition.archived,
            )}`}
          </Tag>
        </Flex>
      )}

      <Image
        fallbackSrc={placeholderImg}
        objectFit="contain"
        src={`/api/files/${imageIdentifier}/preview/256`}
      />
      <Flex alignSelf="center" gap={2} flexWrap="wrap" justifyContent="center">
        {!composition.dimension && (
          <NoData label={t('composition.noDimension')} />
        )}
        {composition.dimension?.dimensions.map((x) => (
          <Tag key={x.id}>
            {x.label}: {x.value}mm
          </Tag>
        ))}
        {convertToEnum(Character, composition.character) !== Character.None && (
          <Tag>{getCharacterLabel(composition.character)}</Tag>
        )}
      </Flex>
      <Box px={4}>
        <Header label={t('general.materials')} />
        <Text fontSize="sm">
          {t('composition.weightAmount', {
            amount: formatWeight(composition.weight),
          })}
        </Text>
      </Box>
      <Accordion allowMultiple={true}>
        {composition.materials.map((x) => (
          <MaterialComposition key={x.id} compositionMaterial={x} />
        ))}
      </Accordion>
    </Flex>
  );
}

function MaterialComposition({
  compositionMaterial,
}: {
  compositionMaterial: CompositionMaterial;
}) {
  const { t } = useTranslation();
  return (
    <AccordionItem>
      <AccordionButton
        gap={2}
        textAlign="start"
        _hover={{ background: 'whiteAlpha.50' }}
        _light={{ _hover: { background: 'blackAlpha.50' } }}
      >
        <Flex flexDir="column">
          <Text fontWeight="bold">{compositionMaterial.material.text}</Text>
          <Text
            overflowWrap="anywhere"
            noOfLines={3}
            fontSize="sm"
            color="gray.300"
          >
            {compositionMaterial.note}
          </Text>
        </Flex>

        <Flex
          gap={2}
          ml="auto"
          minW="32px"
          flexWrap="wrap"
          justifyContent="end"
        >
          {compositionMaterial.colors.map((x) => {
            const color = GetColorFromCode(x.pantoneCode);

            if (!color) return null;
            return <ColorDot key={color.name} hex={color.hex} />;
          })}
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <CompositionDataView
          label={t('configuration.qualities')}
          data={compositionMaterial.qualities}
        />
        <CompositionDataView
          label={t('general.techniques')}
          data={compositionMaterial.techniques}
        />
        <CompositionDataView
          label={t('configuration.treatments')}
          data={compositionMaterial.treatments}
        />
        <Header label={t('general.colors')} />
        <Flex flexWrap="wrap" gap={2}>
          {compositionMaterial.colors.length === 0 && <NoData />}
          {compositionMaterial.colors.map((x) => {
            const color = GetColorFromCode(x.pantoneCode);

            if (!color) return null;
            return (
              <ColorBox
                key={color.name}
                colorDescription={x.colorDescription}
                color={color}
              />
            );
          })}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}

function CompositionDataView({
  data,
  label,
}: {
  data: CompositionData[];
  label: string;
}) {
  return (
    <Box>
      <Header label={label} />
      {data.length === 0 && <NoData />}
      {data.map((x) => (
        <Flex fontSize="sm" key={x.id} alignItems="baseline">
          <Icons.CircleMedium pt="0.5" />
          <Text>{x.text}</Text>
          {x.value && <Text fontWeight="bold">: {x.value}</Text>}
        </Flex>
      ))}
    </Box>
  );
}

function Header({ label }: { label: string }) {
  return (
    <Text w="100%" variant="main" pt={1} pb="0.5">
      {label}
    </Text>
  );
}

function NoData({ label }: { label?: string }) {
  const { t } = useTranslation();

  return <Text color="gray.400">{label ? label : t('general.noData')}</Text>;
}
