import { Box, Button, ButtonProps, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const VerifyButton = ({
  onVerified,
  label,
  buttonProps,
  onVerifyButtonProps = {
    color: 'white',
    backgroundColor: 'red.700',
    borderColor: 'transparent',
    _light: {
      color: 'texas.danger.200 !important',
      backgroundColor: 'texas.danger.50 !important',
      borderColor: 'transparent',
    },
  },
}: {
  onVerified: () => void;
  label: string;
  buttonProps?: ButtonProps;
  onVerifyButtonProps?: ButtonProps;
}) => {
  const [verify, setVerify] = useState(false);
  const { t } = useTranslation();

  return (
    <Button
      {...buttonProps}
      _hover={verify ? { ...onVerifyButtonProps } : undefined}
      onBlur={() => {
        setVerify(false);
      }}
      onClick={() => {
        if (verify) {
          onVerified();
          setVerify(false);
          return;
        }
        setVerify(true);
      }}
    >
      <Box display="grid">
        {/* Parent display is grid since child texts can differ and cause
        different widths, we want to always keep the largest width for the element, thus the usage of grid. Otherwise the layout will change when
        when we toggle the text components */}
        <Text
          gridColumn={1}
          gridRow={1}
          visibility={verify ? 'hidden' : 'visible'}
        >
          {label}
        </Text>
        <Text
          gridColumn={1}
          gridRow={1}
          visibility={verify ? 'visible' : 'hidden'}
        >
          {t('filter.verifyRemove')}
        </Text>
      </Box>
    </Button>
  );
};
