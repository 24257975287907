import {
  GridItem,
  Grid,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { useProductApprovals } from './useProductApprovals';
import { useTranslation } from 'react-i18next';
import { ProductApprovalList } from './ProductApprovalsList';
import { PreviewProductApproval } from './PreviewProductApproval';
import {
  GeneratedProductApprovalState,
  GeneratedProductApproval,
} from '../../../types';
import { useEffect } from 'react';
import { useProdutApprovalWizardState } from '../../../context';

export function SelectProductApprovals({ page }: { page: string }) {
  const { t } = useTranslation();
  const { data, setData, setStepIsValid } = useProdutApprovalWizardState();

  useEffect(() => {
    if (
      data.generatedProductApprovalKeys.length > 0 &&
      data.generatedProductApprovalKeys.filter(
        (key) =>
          data.generatedProductApprovals[key].state ===
          GeneratedProductApprovalState.Unhandled,
      ).length === 0
    ) {
      setStepIsValid(page, true);
      return;
    }
    setStepIsValid(page, false);
  }, [
    data.generatedProductApprovalKeys,
    data.generatedProductApprovalKeys.length,
    data.generatedProductApprovals,
    page,
    setStepIsValid,
  ]);

  const { setSelectedKey, selectedKey, previousRelevantPaRef } =
    useProductApprovals();

  return (
    <Grid gridTemplateColumns="50% 50%">
      <GridItem>
        <Tabs onChange={() => setSelectedKey('')} maxH="780px" overflowY="auto">
          <TabList
            pos="sticky"
            top={0}
            zIndex={1}
            bg="gray.800"
            _light={{ bg: 'gray.10' }}
          >
            <Tab>
              {t('wizard.productApproval.unhandled', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Unhandled,
                ).length,
              })}
            </Tab>
            <Tab>
              {t('wizard.productApproval.countOk', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Accepted,
                ).length,
              })}
            </Tab>
            <Tab>
              {t('wizard.productApproval.ignored', {
                count: filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Ignored,
                ).length,
              })}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Unhandled,
                )}
              />
            </TabPanel>
            <TabPanel>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Accepted,
                )}
              />
            </TabPanel>
            <TabPanel>
              <ProductApprovalList
                nodeGroups={data.articleNodes}
                onHandle={(key, state) => handlePa(key, state)}
                selectedKey={selectedKey}
                onClick={(k) => setSelectedKeyFunc(k)}
                productApprovals={filterProductApprovals(
                  data.generatedProductApprovals,
                  data.generatedProductApprovalKeys,
                  GeneratedProductApprovalState.Ignored,
                )}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </GridItem>
      <GridItem>
        {productApprovalExists(selectedKey, data.generatedProductApprovals) ? (
          <PreviewProductApproval
            productApproval={data.generatedProductApprovals[selectedKey]}
            selectedKey={selectedKey}
            previousReleventPaRef={previousRelevantPaRef.current}
          />
        ) : (
          <Text align="center">{t('productApproval.selectToShowPreview')}</Text>
        )}
      </GridItem>
    </Grid>
  );

  function handlePa(key: string, state: GeneratedProductApprovalState) {
    const index = data.generatedProductApprovalKeys.findIndex((x) => x === key);

    if (index !== -1 && index < data.generatedProductApprovalKeys.length - 1) {
      setSelectedKeyFunc(data.generatedProductApprovalKeys[index + 1]);
    }

    setData((d) => ({
      ...d,
      generatedProductApprovals: {
        ...d.generatedProductApprovals,
        [key]: {
          ...d.generatedProductApprovals[key],
          state: state,
        },
      },
    }));
  }

  function setSelectedKeyFunc(key: string) {
    setSelectedKey(key);

    previousRelevantPaRef.current = previousRelevantPa();

    function previousRelevantPa() {
      const currentPa = data.generatedProductApprovals[key];

      const keyIndex =
        data.generatedProductApprovalKeys.findIndex((x) => x === key) - 1;

      for (let i = keyIndex; i >= 0; i--) {
        const pa =
          data.generatedProductApprovals[data.generatedProductApprovalKeys[i]];
        if (pa.supplier.id === currentPa.supplier.id) {
          return pa;
        }
      }

      return null;
    }
  }
}

function productApprovalExists(
  key: string,
  productApprovals: Record<string, GeneratedProductApproval | undefined>,
) {
  return productApprovals[key] !== undefined;
}

function filterProductApprovals(
  productApprovals: Record<string, GeneratedProductApproval>,
  keys: string[],
  state: GeneratedProductApprovalState,
) {
  return keys.map((k) => productApprovals[k]).filter((p) => p.state === state);
}
