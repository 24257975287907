import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { useTranslation } from 'react-i18next';
import { CompositionGroupView } from './shared/group/CompositionGroupView';

interface Props extends SharedDisclosureProps {
  compositionGroupId: number;
  onRestored: () => void;
}

export function CompositionListModal({
  compositionGroupId,
  isOpen,
  onClose,
  onRestored,
}: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="auto" maxW="fit-content">
        <ModalHeader>{t('composition.compositionsInGroup')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CompositionGroupView
            componentSettings={{
              compositionGroupDots: 'hidden',
              compositionEdit: 'hidden',
            }}
            onUpdated={onRestored}
            variantId={0}
            productGroupId={0}
            compositionGroupId={compositionGroupId}
            onlyArchived={true}
            baseTemplateId={null}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>{t('general.close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
