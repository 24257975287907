import { Flex, Input, useColorMode } from '@chakra-ui/react';
import {
  abovePurchaseProfitThreshold,
  evaluateProfit,
  profitColor,
  setValueAsDecimalExtender,
} from '../shared';
import { useContext } from 'react';
import { SalesContext } from '../Sales';
import { number, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  AdditionalCharge,
  AutoUpdateAdditionalCharge,
  additionalChargeApi,
} from '@texas/api/endpoints/additionalChargeApi';
import { AutoUpdateResponseCallback } from '@texas/components/shared/autoUpdate/types';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { exchangeRateCalculation } from '@texas/utils/helpers/numberHelpers';
import { MarginCalculation } from '../MarginCalculation';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import useFormat from '@texas/hooks/useFormat';
import { defaultNumberOfDecimals } from '@texas/resources/constants';

export interface AdditionalChargeFormProps {
  additionalCharge: AdditionalCharge;
  purchaseInformation?: PurchaseInformation;
  salesCurrency: SalesCurrency;
  isDisabled: boolean;
  selectedCurrency: CustomerCurrency;
  sellingExchangeRate: number;
}

const schema = object({
  surcharge: number().moreThan(-1).typeError('Not a valid number'),
  sampleCharge: number().moreThan(-1).typeError('Not a valid number'),
  mouldCharge: number().moreThan(-1).typeError('Not a valid number'),
}).required();

export function AdditionalChargeForm(props: AdditionalChargeFormProps) {
  const salesContext = useContext(SalesContext);
  const { t } = useTranslation();
  const { numberFormat } = useFormat();
  const { colorMode } = useColorMode();

  const onResponse = (
    data: AutoUpdateResponseCallback<
      AdditionalCharge,
      AutoUpdateAdditionalCharge
    >,
  ) => {
    const toSet =
      salesContext?.salesInformation.data?.map((s) => {
        const salesCurrencies = s.currencies.map((c) => {
          if (c.id === props.salesCurrency.id) {
            c.additionalCharge = {
              ...c.additionalCharge,
              ...data.mappedServerData,
            };
            return c;
          }
          return c;
        });

        s.currencies = salesCurrencies;
        return s;
      }) ?? [];

    salesContext?.salesInformation.set(toSet);
  };

  const {
    register,
    registerAutoUpdate,
    watch,
    formState: { errors },
    form,
    triggerSubmit,
  } = useAutoUpdateForm<AutoUpdateAdditionalCharge, AdditionalCharge>({
    defaultValue: {
      mouldCharge: props.additionalCharge.mouldCharge,
      sampleCharge: props.additionalCharge.sampleCharge,
      surcharge: props.additionalCharge.surcharge,
    },
    valueId: props.salesCurrency.id,
    namePrefix: 'additionalCharge',
    autoUpdateRequest: additionalChargeApi.autoUpdateAdditionalCharge,
    onResponse,
    resolver: yupResolver(schema),
  });

  const [mouldCharge, sampleCharge, surcharge] = watch([
    'mouldCharge',
    'sampleCharge',
    'surcharge',
  ]);

  const surchargeProfit = evaluateProfit(
    exchangeRateCalculation(
      surcharge,
      props.salesCurrency.fixedExchangeRate,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    exchangeRateCalculation(
      props.purchaseInformation?.surcharge ?? 0,
      props.purchaseInformation?.fixedExchangeRate ?? 0,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    abovePurchaseProfitThreshold,
  );
  const mouldChargeProfit = evaluateProfit(
    exchangeRateCalculation(
      mouldCharge,
      props.salesCurrency.fixedExchangeRate,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    exchangeRateCalculation(
      props.purchaseInformation?.mouldCharge ?? 0,
      props.purchaseInformation?.fixedExchangeRate ?? 0,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    abovePurchaseProfitThreshold,
  );
  const sampleChargeProfit = evaluateProfit(
    exchangeRateCalculation(
      sampleCharge,
      props.salesCurrency.fixedExchangeRate,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    exchangeRateCalculation(
      props.purchaseInformation?.sampleCharge ?? 0,
      props.purchaseInformation?.fixedExchangeRate ?? 0,
      props.sellingExchangeRate,
      defaultNumberOfDecimals,
    ),
    abovePurchaseProfitThreshold,
  );

  return (
    <form {...form}>
      <fieldset disabled={props.isDisabled}>
        <Flex gap={2} alignItems="start">
          <TexasInputWrapper
            bottomLabel={`${numberFormat(
              exchangeRateCalculation(
                props.purchaseInformation?.sampleCharge ?? 0,
                props.purchaseInformation?.fixedExchangeRate ?? 0,
                props.sellingExchangeRate,
                defaultNumberOfDecimals,
              ),
            )} ${props.selectedCurrency.code}`}
            label={t('variant.sales.sampleCharge')}
            rightAddon={props.salesCurrency.currencyCode}
            error={errors.sampleCharge}
            utilButtons={
              <MarginCalculation
                purchasePrice={props.purchaseInformation?.sampleCharge ?? 0}
                purchaseExchangeRate={
                  props.purchaseInformation?.fixedExchangeRate ?? 0
                }
                salesPrice={sampleCharge}
                salesExchangeRate={props.salesCurrency.fixedExchangeRate}
                targetExchangeRate={props.selectedCurrency.buyingRate}
                targetCurrencyCode={props.selectedCurrency.code}
                profit={sampleChargeProfit}
              />
            }
          >
            <AutoUpdateWrapper
              autoUpdateProps={registerAutoUpdate}
              path="sampleCharge"
            >
              <Input
                color={profitColor(sampleChargeProfit, colorMode === 'dark')}
                {...register('sampleCharge', {
                  setValueAs: (value) =>
                    setValueAsDecimalExtender(value, false),
                  onBlur: triggerSubmit,
                })}
                placeholder="0"
              />
            </AutoUpdateWrapper>
          </TexasInputWrapper>
          <TexasInputWrapper
            bottomLabel={`${numberFormat(
              exchangeRateCalculation(
                props.purchaseInformation?.surcharge ?? 0,
                props.purchaseInformation?.fixedExchangeRate ?? 0,
                props.sellingExchangeRate,
                defaultNumberOfDecimals,
              ),
            )} ${props.selectedCurrency.code}`}
            label={t('variant.sales.surcharge')}
            rightAddon={props.salesCurrency.currencyCode}
            error={errors.surcharge}
            utilButtons={
              <MarginCalculation
                purchasePrice={props.purchaseInformation?.surcharge ?? 0}
                purchaseExchangeRate={
                  props.purchaseInformation?.fixedExchangeRate ?? 0
                }
                salesPrice={surcharge}
                salesExchangeRate={props.salesCurrency.fixedExchangeRate}
                targetExchangeRate={props.selectedCurrency.buyingRate}
                targetCurrencyCode={props.selectedCurrency.code}
                profit={surchargeProfit}
              />
            }
          >
            <AutoUpdateWrapper
              autoUpdateProps={registerAutoUpdate}
              path="surcharge"
            >
              <Input
                color={profitColor(surchargeProfit, colorMode === 'dark')}
                {...register('surcharge', {
                  onBlur: triggerSubmit,
                  setValueAs: (value) =>
                    setValueAsDecimalExtender(value, false),
                })}
                placeholder="0"
              />
            </AutoUpdateWrapper>
          </TexasInputWrapper>
          <TexasInputWrapper
            bottomLabel={`${numberFormat(
              exchangeRateCalculation(
                props.purchaseInformation?.mouldCharge ?? 0,
                props.purchaseInformation?.fixedExchangeRate ?? 0,
                props.sellingExchangeRate,
                defaultNumberOfDecimals,
              ),
            )} ${props.selectedCurrency.code}`}
            label={t('variant.sales.mouldCharge')}
            rightAddon={props.salesCurrency.currencyCode}
            error={errors.mouldCharge}
            utilButtons={
              <MarginCalculation
                purchasePrice={props.purchaseInformation?.mouldCharge ?? 0}
                purchaseExchangeRate={
                  props.purchaseInformation?.fixedExchangeRate ?? 0
                }
                salesPrice={mouldCharge}
                salesExchangeRate={props.salesCurrency.fixedExchangeRate}
                targetExchangeRate={props.selectedCurrency.buyingRate}
                targetCurrencyCode={props.selectedCurrency.code}
                profit={mouldChargeProfit}
              />
            }
          >
            <AutoUpdateWrapper
              autoUpdateProps={registerAutoUpdate}
              path="mouldCharge"
            >
              <Input
                color={profitColor(mouldChargeProfit, colorMode === 'dark')}
                {...register('mouldCharge', {
                  setValueAs: (value) =>
                    setValueAsDecimalExtender(value, false),
                  onBlur: triggerSubmit,
                })}
                placeholder="0"
              />
            </AutoUpdateWrapper>
          </TexasInputWrapper>
        </Flex>
      </fieldset>
    </form>
  );
}
