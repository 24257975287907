import {
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputProps,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { Icons } from './shared/Icons';

export type SearchFilterStyle = 'unstyled' | 'border' | 'navbar';

export interface SearchFilterInputProps {
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  debounceDelay?: number;
  placeholder?: string;
  style?: SearchFilterStyle;
  autoFocus?: boolean;
}

const defaultDebounceDelay = 350;

export const SearchFilterInput = React.forwardRef<
  HTMLInputElement,
  SearchFilterInputProps
>(function SearchFilterInput(props, ref) {
  const [inputValue, setInputValue] = useState('');
  const inputValueRef = useRef('');
  const { t } = useTranslation();

  function setInputValueWithRef(value: string) {
    inputValueRef.current = value;
    setInputValue(value);
  }

  useEffect(() => {
    if (props.value !== inputValueRef.current) {
      setInputValueWithRef(props.value);
    }
  }, [props.value]);

  const onChangeDebounced = useDebouncedCallback(
    props.onChange,
    props.debounceDelay ?? defaultDebounceDelay,
  );

  let style: { inputGroup: InputGroupProps; input: InputProps };
  switch (props.style) {
    case 'unstyled':
      {
        style = { inputGroup: {}, input: {} };
      }
      break;
    case 'navbar': {
      style = {
        inputGroup: {
          borderBottomWidth: 1,
          borderColor: 'whiteAlpha.200',
          padding: '2',
          _focusWithin: {
            borderColor: 'whiteAlpha.400',
          },
          _hover: { borderColor: 'whiteAlpha.400' },
          _light: {
            borderColor: 'whiteAlpha.200',
            _hover: { borderColor: 'whiteAlpha.400' },
            _focusWithin: {
              borderColor: 'whiteAlpha.400',
            },
          },
        },
        input: {
          color: 'white',
          _placeholder: { color: 'whiteAlpha.500' },
          _light: { color: 'white' },
          sx: {
            ':-webkit-autofill': {
              WebkitTextFillColor: 'white !important',
            },
            ':-webkit-autofill:hover': {
              WebkitTextFillColor: 'white !important',
            },
            ':-webkit-autofill:focus': {
              WebkitTextFillColor: 'white !important',
            },
          },
        },
      };
      break;
    }
    case undefined:
    case 'border': {
      style = {
        inputGroup: {
          borderBottomWidth: 1,
          borderColor: 'gray.500',
          padding: '2',
          _focusWithin: {
            borderColor: 'gray.400',
          },
          _hover: {
            borderColor: 'gray.400',
          },

          _light: {
            borderColor: 'gray.200',
            color: 'texas.bg.800',
            _hover: {
              borderColor: 'gray.300',
            },
            _focusWithin: {
              borderColor: 'gray.300',
            },
          },
        },
        input: {
          _placeholder: {
            color: 'whiteAlpha.500',
          },
          _light: {
            color: 'texas.bg.800',
            _placeholder: { color: 'blackAlpha.500' },
          },
          _dark: {
            ':-webkit-autofill': {
              '-webkit-text-fill-color': 'white !important',
            },
            ':-webkit-autofill:hover': {
              '-webkit-text-fill-color': 'white !important',
            },
            ':-webkit-autofill:focus': {
              '-webkit-text-fill-color': 'white !important',
            },
          },
        },
      };
      break;
    }
  }

  return (
    <InputGroup
      {...style.inputGroup}
      transition="border-color 200ms ease"
      variant="unstyled"
      onFocus={props.onFocus}
      onBlur={props.onBlur}
    >
      <InputLeftAddon mr={2}>
        <Icons.Search boxSize={6} _light={{ color: 'gray.200' }} />
      </InputLeftAddon>
      <Input
        {...style.input}
        name={t('general.search')}
        ref={ref}
        type="search"
        placeholder={props.placeholder ?? t('general.search')}
        fontSize={16}
        value={inputValue}
        onChange={(e) => {
          setInputValueWithRef(e.target.value);
          onChangeDebounced(e.target.value);
        }}
        autoFocus={props.autoFocus}
      />
    </InputGroup>
  );
});
