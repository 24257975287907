import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Input,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { ContactAddToType } from '@texas/api/endpoints/contactsApi';
import { TemplateCard } from '@texas/components/article/variant/compositions/shared/composition/TemplateCard';
import {
  MetadataList,
  MetadataListRow,
} from '@texas/components/shared/MetadataList';
import {
  nodeGroupIsPopulated,
  formatNodeGroup,
} from '@texas/utils/helpers/nodeGroupHelpers';
import { CopyPreviousValue } from './CopyPreviousValue';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { GeneratedProductApproval } from '../../../types';
import { ContactsModal } from '@texas/components/contacts/ContactsModal';
import { useProdutApprovalWizardState } from '../../../context';
import { CompositionGroupView } from '@texas/components/article/variant/compositions/shared/group/CompositionGroupView';

export function PreviewProductApproval({
  productApproval,
  selectedKey,
  previousReleventPaRef,
}: {
  productApproval: GeneratedProductApproval;
  selectedKey: string;
  previousReleventPaRef: GeneratedProductApproval | null | undefined;
}) {
  const { t } = useTranslation();
  const { data, setData } = useProdutApprovalWizardState();
  const {
    isOpen: isSupplierAddContactOpen,
    onClose: onSupplierAddContactClose,
    onOpen: onSupplierAddContactOpen,
  } = useDisclosure();
  const {
    isOpen: isCustomerAddContactOpen,
    onClose: onCustomerAddContactClose,
    onOpen: onCustomerAddContactOpen,
  } = useDisclosure();

  return (
    <Flex flexDirection="column">
      <ContactsModal
        mode="select"
        filterOn={ContactAddToType.Supplier}
        addTo={ContactAddToType.Supplier}
        referenceId={productApproval.supplier.id}
        onClose={() => {
          onSupplierAddContactClose();
        }}
        isOpen={isSupplierAddContactOpen}
        onSelect={(c) => {
          setData((d) => ({
            ...d,
            generatedProductApprovals: {
              ...d.generatedProductApprovals,
              [selectedKey]: {
                ...d.generatedProductApprovals[selectedKey],
                supplierContact: { name: c.name, id: c.id },
              },
            },
          }));
          onSupplierAddContactClose();
        }}
      />
      <ContactsModal
        mode="select"
        filterOn={ContactAddToType.Customer}
        addTo={ContactAddToType.Customer}
        referenceId={data.customerId}
        onClose={() => {
          onCustomerAddContactClose();
        }}
        isOpen={isCustomerAddContactOpen}
        onSelect={(c) => {
          setData((d) => ({
            ...d,
            generatedProductApprovals: {
              ...d.generatedProductApprovals,
              [selectedKey]: {
                ...d.generatedProductApprovals[selectedKey],
                customerContact: { name: c.name, id: c.id },
              },
            },
          }));
          onCustomerAddContactClose();
        }}
      />
      <Tabs>
        <TabList>
          <Tab>{t('general.info')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {selectedKey.length === 0 ? (
              <Text>{t('wizard.productApproval.noSelected')}</Text>
            ) : (
              <Flex gap={1}>
                <Flex flexDir="column" w={80}>
                  <MetadataList>
                    <MetadataListRow label={t('general.branch')}>
                      <Text textAlign="end">
                        {productApproval.supplier.branch.name}
                      </Text>
                    </MetadataListRow>
                    <MetadataListRow label={t('productApproval.issuedBy')}>
                      <Text textAlign="end">
                        {productApproval.issuedBy.name}
                      </Text>
                    </MetadataListRow>
                    <MetadataListRow label={t('general.supplier')}>
                      <Text textAlign="end">
                        {productApproval.supplier.name}
                      </Text>
                    </MetadataListRow>
                    <MetadataListRow label={t('composition.composition')}>
                      <Text textAlign="end">
                        {compositionOwner(t, productApproval)}
                      </Text>
                    </MetadataListRow>
                    {nodeGroupIsPopulated(data.articleNodes) && (
                      <MetadataListRow label={t('general.cell')}>
                        <Text textAlign="end">
                          {formatNodeGroup(
                            data.articleNodes!.nodeXValues[
                              productApproval.cell!.xIndex
                            ],
                            data.articleNodes!.nodeYValues[
                              productApproval.cell!.yIndex
                            ],
                          )}
                        </Text>
                      </MetadataListRow>
                    )}
                    <MetadataListRow
                      dir="column"
                      label={t('suppliers.supplierContact')}
                    >
                      <>
                        <Button
                          variant="texas-light"
                          my={1}
                          width="full"
                          onClick={() => onSupplierAddContactOpen()}
                        >
                          {productApproval.supplierContact?.name ??
                            t('contacts.selectContact')}
                        </Button>
                        <CopyPreviousValue
                          previousPa={previousReleventPaRef}
                          value={previousReleventPaRef?.supplierContact?.name}
                          onCopy={(pa) => {
                            setData((d) => ({
                              ...d,
                              generatedProductApprovals: {
                                ...d.generatedProductApprovals,
                                [selectedKey]: {
                                  ...d.generatedProductApprovals[selectedKey],
                                  supplierContact: pa.supplierContact,
                                },
                              },
                            }));
                          }}
                        />
                      </>
                    </MetadataListRow>
                    <MetadataListRow
                      dir="column"
                      label={t('customer.customerContact')}
                    >
                      <Button
                        variant="texas-light"
                        my={1}
                        width="full"
                        onClick={() => onCustomerAddContactOpen()}
                      >
                        {productApproval.customerContact?.name ??
                          t('contacts.selectContact')}
                      </Button>
                    </MetadataListRow>
                    <MetadataListRow
                      dir="column"
                      label={t('productApproval.externalItemCode')}
                    >
                      <>
                        <Input
                          bg="whiteAlpha.200"
                          _light={{ bg: 'blackAlpha.50' }}
                          padding="8px 4px"
                          placeholder="Item code"
                          value={productApproval.supplierExternalItemCode}
                          onChange={(e) => {
                            setData((d) => ({
                              ...d,
                              generatedProductApprovals: {
                                ...d.generatedProductApprovals,
                                [selectedKey]: {
                                  ...d.generatedProductApprovals[selectedKey],
                                  supplierExternalItemCode: e.target.value,
                                },
                              },
                            }));
                          }}
                        />
                        <CopyPreviousValue
                          previousPa={previousReleventPaRef}
                          value={
                            previousReleventPaRef?.supplierExternalItemCode
                          }
                          onCopy={(pa) => {
                            setData((d) => ({
                              ...d,
                              generatedProductApprovals: {
                                ...d.generatedProductApprovals,
                                [selectedKey]: {
                                  ...d.generatedProductApprovals[selectedKey],
                                  supplierExternalItemCode:
                                    pa.supplierExternalItemCode,
                                },
                              },
                            }));
                          }}
                        />
                      </>
                    </MetadataListRow>
                    <MetadataListRow
                      dir="column"
                      label={t('productApproval.supplierNote')}
                    >
                      <>
                        <Textarea
                          value={productApproval.supplierNote}
                          onChange={(e) => {
                            setData((d) => ({
                              ...d,
                              generatedProductApprovals: {
                                ...d.generatedProductApprovals,
                                [selectedKey]: {
                                  ...d.generatedProductApprovals[selectedKey],
                                  supplierNote: e.target.value,
                                },
                              },
                            }));
                          }}
                        />
                        <CopyPreviousValue
                          previousPa={previousReleventPaRef}
                          value={previousReleventPaRef?.supplierNote}
                          onCopy={(pa) => {
                            setData((d) => ({
                              ...d,
                              generatedProductApprovals: {
                                ...d.generatedProductApprovals,
                                [selectedKey]: {
                                  ...d.generatedProductApprovals[selectedKey],
                                  supplierNote: pa.supplierNote,
                                },
                              },
                            }));
                          }}
                        />
                      </>
                    </MetadataListRow>
                  </MetadataList>
                </Flex>
                {productApproval.compositionGroup !== null ? (
                  <CompositionGroupView
                    componentSettings={{
                      compositionArchiveRestore: 'hidden',
                      compositionEdit: 'hidden',
                      compositionGroupDots: 'hidden',
                      expander: 'hidden',
                    }}
                    compositionGroupId={productApproval.compositionGroup.id}
                    variantId={productApproval.compositionGroup.variantId}
                    productGroupId={0}
                    baseTemplateId={null}
                  />
                ) : (
                  <TemplateCard exists={false} highlight={false}>
                    <Text p={2} textAlign="center">
                      {t('composition.noComposition')}
                    </Text>
                  </TemplateCard>
                )}
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

function compositionOwner(
  t: TFunction<'translation'>,
  productApproval: GeneratedProductApproval,
) {
  if (!productApproval.compositionGroup) {
    return t('composition.noComposition');
  }

  if (productApproval.cell) {
    if (
      productApproval.compositionGroup.supplierId ===
        productApproval.supplier.id &&
      productApproval.compositionGroup.branchId ===
        productApproval.supplier.branch.id &&
      productApproval.compositionGroup.cellX === productApproval.cell.x &&
      productApproval.compositionGroup.cellY === productApproval.cell.y
    ) {
      return t('composition.supplierComposition');
    } else if (
      productApproval.compositionGroup.cellX === productApproval.cell.x &&
      productApproval.compositionGroup.cellY === productApproval.cell.y
    ) {
      return t('composition.baseCellTemplate');
    }
  } else if (
    productApproval.compositionGroup.supplierId ===
      productApproval.supplier.id &&
    productApproval.compositionGroup.branchId ===
      productApproval.supplier.branch.id
  ) {
    return t('composition.supplierComposition');
  }

  return t('composition.baseTemplate');
}
