import { Flex, Tab, Text } from '@chakra-ui/react';
import { Step } from './types';
import { Icons } from '@texas/components/shared/Icons';

export function StepTab({ step }: { step: Step }) {
  const state = step.state;

  return (
    <Flex align="center">
      <Tab
        boxSize="28"
        bg={step.state.isActive ? 'gray.700' : 'transparent'}
        _hover={{
          bg: step.state.isActive ? 'gray.700' : 'whiteAlpha.100',
        }}
        _light={{
          bg: step.state.isActive ? 'gray.50' : 'transparent',
          _hover: { bg: step.state.isActive ? 'gray.50' : 'blackAlpha.100' },
        }}
        flexDirection="column"
      >
        <Text
          fontWeight="bold"
          color={state.isValid || state.isActive ? 'white' : 'gray.300'}
          _light={{
            color:
              state.isValid || state.isActive ? 'texas.bg.900' : 'gray.400',
          }}
        >
          {step.config.label}
        </Text>
      </Tab>
      {!step.last && (
        <Icons.ArrowRightThick
          boxSize={8}
          color={state.isValid ? 'white' : 'gray.400'}
          _light={{
            color: state.isValid ? 'black' : 'gray.300',
          }}
        />
      )}
    </Flex>
  );
}
