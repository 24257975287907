import {
  FormControl,
  InputGroup,
  InputRightElement,
  FormErrorMessage,
  Flex,
  Badge,
  FormControlProps,
  FormLabel,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

export interface TexasInputWrapperProps {
  label?: string;
  bottomLabel?: string;
  children: React.ReactNode;
  utilButtons?: React.ReactNode;
  rightAddon?: string;
  error?: FieldError;
  formControlProps?: FormControlProps;
}

export function TexasInputWrapper(props: TexasInputWrapperProps) {
  return (
    <FormControl
      {...props.formControlProps}
      isInvalid={props.error !== undefined}
      alignItems="start"
    >
      {props.label && <FormLabel>{props.label}</FormLabel>}

      <InputGroup
        padding={1}
        bg="texas.bg.800"
        _light={{ bg: 'gray.10' }}
        minW={52}
        borderRadius={4}
        role="group"
      >
        {props.children}

        {(props.utilButtons || props.rightAddon) && (
          <InputRightElement
            top="unset"
            borderRadius={4}
            pos="static"
            flexGrow={1}
            justifyContent="end"
          >
            <Flex gap={1}>
              {props.rightAddon && <>{props.rightAddon}</>}
              {props.utilButtons && <>{props.utilButtons}</>}
            </Flex>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage fontSize={12}>{props.error?.message}</FormErrorMessage>
      <Flex pt={1}>
        {props.bottomLabel && <Badge>{props.bottomLabel}</Badge>}
      </Flex>
    </FormControl>
  );
}
