import { Card } from '@chakra-ui/react';

export function TemplateCard({
  children,
  exists,
  highlight,
}: {
  children: React.ReactNode;
  exists: boolean;
  highlight: boolean;
}) {
  return (
    <Card
      variant="composition-template"
      borderColor={highlight ? 'gray.200' : 'texas.bg.700'}
      _light={{ borderColor: highlight ? 'texas.bg.700' : 'gray.200' }}
      w="300px"
      h="700px"
      overflowY="auto"
      {...(!exists
        ? { borderStyle: 'dashed', background: 'transparent' }
        : null)}
    >
      {children}
    </Card>
  );
}
