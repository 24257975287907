import { Box, useColorMode } from '@chakra-ui/react';

export function BodyOverride({ children }: { children: React.ReactNode }) {
  // Override the body BG background on fully styled react pages
  // This is a hack that is temporary and will be removed when
  // react is fully implemented
  // But this needs to exist in the meantime since we have 2 styles at the same time..
  const { colorMode } = useColorMode();

  const color =
    colorMode === 'dark'
      ? '\
        body, html {\
          background: var(--chakra-colors-texas-bg-dark);\
        }\
      '
      : '\
        body, html {\
          background: var(--chakra-colors-texas-bg-light);\
        }\
      ';
  return (
    <Box background="texas.bg.dark" _light={{ background: 'texas.bg.light' }}>
      <style>{color}</style>
      {children}
    </Box>
  );
}
