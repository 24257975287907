import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  chakra,
  TableHeadProps,
  Checkbox,
} from '@chakra-ui/react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  Row,
  getFilteredRowModel,
  RowSelectionState,
} from '@tanstack/react-table';
import { LoadingOverlay } from '../LoadingOverlay';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

interface SelectionProps {
  selectedRows: RowSelectionState;
  setSelectedRows: Dispatch<SetStateAction<RowSelectionState>>;
}

interface RowProps<Data> {
  onRowClick?: Dispatch<SetStateAction<number>>;
  getRowId:
    | ((
        originalRow: Data,
        index: number,
        parent?: Row<Data> | undefined,
      ) => string)
    | undefined;
}

export interface DataTableProps<Data extends object> {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  isLoading?: boolean;
  sorted?: SortedOptions;
  tableHeadProps?: TableHeadProps;
  selectionProps?: SelectionProps;
  rowProps?: RowProps<Data>;
  variant?: string;
}

interface SortedOptions {
  key: string;
  desc: boolean;
  onSortedChange: (newSorting: { key: string; desc: boolean }) => void;
}

const sortIconSize = 4;

export function DataTable<Data extends object>({
  data,
  columns,
  isLoading,
  sorted,
  tableHeadProps,
  selectionProps = undefined,
  rowProps = undefined,
  variant = 'stickyHeader',
}: DataTableProps<Data>) {
  const { t } = useTranslation();
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: rowProps?.getRowId,
    state: {
      rowSelection: selectionProps?.selectedRows,
    },
    enableRowSelection: true,
    onRowSelectionChange: selectionProps?.setSelectedRows,
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
  });

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    tableRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [data]);

  return (
    <Box
      position="relative"
      zIndex={0}
      overflowX="auto"
      ref={(r) => (tableRef.current = r)}
    >
      {isLoading && <LoadingOverlay />}
      <Table variant={variant}>
        <Thead {...tableHeadProps}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {selectionProps && (
                <Th paddingLeft={2}>
                  <Checkbox
                    isIndeterminate={table.getIsSomePageRowsSelected()}
                    isChecked={table.getIsAllPageRowsSelected()}
                    onChange={table.getToggleAllPageRowsSelectedHandler()}
                  />
                </Th>
              )}
              {headerGroup.headers.map((header) => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    width={`${header.getSize()}px`}
                    key={header.id}
                    _hover={
                      header.column.getCanSort()
                        ? { cursor: 'pointer', opacity: '0.5' }
                        : { cursor: 'not-allowed' }
                    }
                    onClick={() => {
                      if (!header.column.getCanSort()) return;
                      sorted?.onSortedChange({
                        key: header.column.id,
                        desc:
                          header.column.id === sorted.key
                            ? !sorted.desc
                            : false,
                      });
                    }}
                    isNumeric={meta?.isNumeric}
                    paddingX={2}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}

                    <chakra.span color="body-text" pl="4">
                      {header.column.id === sorted?.key ? (
                        sorted.desc ? (
                          <Icons.SortDescending
                            width={sortIconSize}
                            height={sortIconSize}
                            aria-label="sorted descending"
                          />
                        ) : (
                          <Icons.SortAscending
                            width={sortIconSize}
                            height={sortIconSize}
                            aria-label="sorted ascending"
                          />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {table.getRowModel().rows.length == 0 ? (
          <Tbody>
            <Tr>
              <Td
                colSpan={100}
                textAlign="center"
                color="whiteAlpha.600"
                _light={{ color: 'blackAlpha.600' }}
                p={8}
              >
                {t('general.noData')}
              </Td>
            </Tr>
          </Tbody>
        ) : (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.index}
                _hover={{
                  background: 'whiteAlpha.200',
                  cursor: rowProps?.onRowClick ? 'pointer' : 'inherit',
                }}
                _light={{
                  _hover: { background: 'gray.10' },
                }}
                onClick={() => {
                  if (rowProps?.onRowClick === undefined) return;

                  const id = parseInt(row.id);
                  rowProps.onRowClick(id);
                }}
              >
                {selectionProps && (
                  <Td>
                    <Checkbox
                      isChecked={row.getIsSelected()}
                      onChange={row.getToggleSelectedHandler()}
                    />
                  </Td>
                )}
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      key={cell.id}
                      fontSize="12px"
                      isNumeric={meta?.isNumeric}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>
    </Box>
  );
}
