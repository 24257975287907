import { SelectBranches } from './branches/SelectBranches';
import { StepConfig } from '../types';
import { SelectSuppliers } from './suppliers/SelectSuppliers';
import { SelectProductApprovals } from './selectProductApprovals/SelectProductApprovals';
import { SelectCells } from './matrix/SelectCells';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { nodeGroupIsPopulated } from '@texas/utils/helpers/nodeGroupHelpers';
import { Confirm } from './confirm/Confirm';
import { useTranslation } from 'react-i18next';

export function useCreateProductApprovalsSteps(
  articleNodeGroups?: ArticleNodeGroup,
): StepConfig[] {
  const { t } = useTranslation();
  return [
    {
      id: 'branches',
      content: <SelectBranches page="branches" />,
      label: <>{t('wizard.productApproval.selectBranches')}</>,
      last: false,
    },
    {
      id: 'suppliers',
      content: <SelectSuppliers page="suppliers" />,
      label: <>{t('wizard.productApproval.selectSuppliers')}</>,
      last: false,
    },
    ...(nodeGroupIsPopulated(articleNodeGroups)
      ? [
          {
            id: 'matrix',
            content: (
              <SelectCells
                page="matrix"
                articleNodeGroup={articleNodeGroups!}
              />
            ),
            label: <>{t('wizard.productApproval.selectCells')}</>,
            last: false,
          },
        ]
      : []),
    {
      id: 'productApprovals',
      content: <SelectProductApprovals page="productApprovals" />,
      label: <>{t('wizard.productApproval.productApprovals')}</>,
      last: false,
    },
    {
      id: 'confirm',
      content: <Confirm page="confirm" />,
      label: <>{t('general.confirm')}</>,
      customSummary: <></>,
      last: true,
    },
  ];
}
