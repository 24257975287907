import { Flex, Box, Text, useColorMode, ColorMode } from '@chakra-ui/react';

export function MetadataList({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) {
  const { colorMode } = useColorMode();

  if (!Array.isArray(children)) {
    return <Box bg={metaListBackground(0, colorMode)}>{children}</Box>;
  }

  return (
    <Flex flexDir="column">
      {children
        .filter((c) => c !== null && c != false)
        .map((c, i) => (
          <Box
            bg={metaListBackground(i, colorMode)}
            _light={{ bg: metaListBackground(i, colorMode) }}
            key={i}
            p={2}
          >
            {c}
          </Box>
        ))}
    </Flex>
  );
}

export function MetadataListRow({
  label,
  dir = 'row',
  children,
}: {
  label: string;
  dir?: 'column' | 'row';
  children: React.ReactNode;
}) {
  return (
    <Flex
      flexDir={dir}
      alignItems={dir === 'row' ? 'flex-start' : 'normal'}
      justify="space-between"
    >
      <Text fontWeight="bold" color="gray.200" _light={{ color: 'gray.600' }}>
        {label}
      </Text>
      <Box textAlign="right">{children}</Box>
    </Flex>
  );
}

function metaListBackground(index: number, colorMode: ColorMode) {
  if (index % 2 === 0) {
    return colorMode === 'dark' ? 'whiteAlpha.50' : 'blackAlpha.50';
  }
  return 'transparent';
}
