import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  useColorMode,
  Flex,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServerError } from '@texas/types';
import { exchangeRateCalculation } from '@texas/utils/helpers/numberHelpers';
import {
  profitColor,
  evaluateProfit,
  setValueAsDecimalExtender,
} from './shared';
import { request } from '@texas/utils/helpers/httpHelpers';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import {
  MassUpdateSalesCellsPricesResponse,
  tierPriceApi,
} from '@texas/api/endpoints/tierPriceApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import useFormat from '@texas/hooks/useFormat';
import { defaultNumberOfDecimals } from '@texas/resources/constants';
import { Margin } from './common/Margin';
import { Multiplied } from './common/Multiplied';
import { MatrixCell } from './MatrixView';
import { blinkAnimation } from '@texas/resources/animations/animations';

interface SetCellPricesProps {
  cells: MatricCellWithPrice[];
  purchaseInfo: PurchaseInformation;
  sellingExchangeRate: number;
  salesCurrency: SalesCurrency;
  tierPriceId: number;
  onClose: () => void;
  isOpen: boolean;
  onUpdate: (data: MassUpdateSalesCellsPricesResponse) => void;
}

export interface MatricCellWithPrice extends MatrixCell {
  currentPrice: number;
  purchasePriceWithFees: number;
}

export function SetCellPrices(props: SetCellPricesProps) {
  const [newCellPrices, setNewCellPrices] = useState(0);
  const { t } = useTranslation();
  const { numberFormat } = useFormat();
  const { colorMode } = useColorMode();

  const toast = useToast();
  const {
    request: remoteRequest,
    error,
    loading,
  } = useApiRequest(tierPriceApi.massUpdateCellsPrices);

  useEffect(() => {
    setNewCellPrices(0);
  }, [props.isOpen]);

  return (
    <Modal size="5xl" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>
          {t('variant.sales.setCellPricesForCurrency', {
            currency: props.salesCurrency.currencyCode,
          })}
        </ModalHeader>
        <ModalBody>
          <ErrorDetails error={error} />
          <Flex direction="column" justify="center">
            <TableContainer flexGrow={1}>
              <Table
                size="md"
                w="full"
                maxHeight="380px"
                overflowY="auto"
                overflowX="hidden"
                variant="striped"
                colorScheme="gray"
              >
                <Thead pos="sticky" top="0" background="texas.bg.800">
                  <Tr>
                    <Th color="white">{t('branch.identifier')}</Th>
                    <Th color="white">
                      {t('general.cell')} <br />
                      {t('variant.sales.purchasePrice')}
                    </Th>
                    <Th color="white">
                      {t('general.current')} <br />
                      {t('variant.sales.cellPrice')}
                    </Th>
                    <Th color="white" fontWeight="bold">
                      {t('general.new')} <br />
                      {t('variant.sales.cellPrice')}
                    </Th>
                    <Th color="white">{t('variant.sales.margin')}</Th>
                    <Th color="white">{t('variant.sales.multiplier')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {props.cells.map((c) => {
                    return (
                      <Tr key={`${c.matrixNodeXId}${c.matrixNodeYId}`}>
                        <Td>{c.identifier}</Td>
                        <Td>{`${numberFormat(
                          exchangeRateCalculation(
                            c.purchasePriceWithFees,
                            props.purchaseInfo.fixedExchangeRate,
                            props.salesCurrency.fixedExchangeRate,
                            defaultNumberOfDecimals,
                          ),
                        )} ${props.salesCurrency.currencyCode}`}</Td>
                        <Td>{`${numberFormat(c.currentPrice)} ${
                          props.salesCurrency.currencyCode
                        }`}</Td>
                        <Td
                          fontWeight="bold"
                          animation={blinkAnimation()}
                          color={profitColor(
                            evaluateProfit(
                              exchangeRateCalculation(
                                newCellPrices,
                                props.salesCurrency.fixedExchangeRate,
                                props.sellingExchangeRate,
                                defaultNumberOfDecimals,
                              ),
                              exchangeRateCalculation(
                                c.purchasePriceWithFees,
                                props.salesCurrency.fixedExchangeRate,
                                props.sellingExchangeRate,
                                defaultNumberOfDecimals,
                              ),
                            ),
                            colorMode === 'dark',
                          )}
                        >
                          {`${newCellPrices} ${props.salesCurrency.currencyCode}`}
                        </Td>
                        <Td>
                          <Margin
                            salesCurrency={props.salesCurrency}
                            price={newCellPrices}
                            purchasePrice={c.purchasePriceWithFees}
                            purchaseInformation={props.purchaseInfo}
                            sellingExchangeRate={props.sellingExchangeRate}
                          />
                        </Td>
                        <Td>
                          <Multiplied
                            salesCurrency={props.salesCurrency}
                            price={newCellPrices}
                            purchasePrice={c.purchasePriceWithFees}
                            purchaseInformation={props.purchaseInfo}
                            sellingExchangeRate={props.sellingExchangeRate}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          <Flex my={4}>
            <TexasInputWrapper
              label={t('variant.sales.enterNewCellPrice')}
              rightAddon={props.salesCurrency.currencyCode}
            >
              <Input
                placeholder="0"
                onChange={(e) =>
                  setNewCellPrices(
                    setValueAsDecimalExtender(e.target.value, false),
                  )
                }
              />
            </TexasInputWrapper>
          </Flex>
        </ModalBody>
        <ModalFooter
          backgroundColor="gray.800"
          _light={{ backgroundColor: 'gray.50' }}
        >
          <Button variant="texas-outline-light" onClick={props.onClose}>
            {t('general.close')}
          </Button>
          <Button
            isDisabled={loading}
            isLoading={loading}
            variant="texas-solid"
            onClick={async () => {
              await performRequest();
            }}
            ml={3}
          >
            {t('variant.sales.setPrices')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  async function performRequest() {
    await request(
      remoteRequest,
      [
        props.tierPriceId,
        props.salesCurrency.id,
        {
          priceCells: props.cells.map((c) => ({
            price: newCellPrices,
            matrixNodeValueXId: c.matrixNodeXId,
            matrixNodeValueYId: c.matrixNodeYId,
          })),
        },
      ],
      (data) => {
        toast({
          title: t('variant.sales.cellPricesSet'),
          status: 'success',
          isClosable: true,
        });
        props.onUpdate(data);
        props.onClose();
      },
      (error: ServerError) => {
        toast({
          title: t('variant.sales.failedToSetCellPrices'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
