import {
  CardBody,
  Flex,
  Box,
  Button,
  IconButton,
  Tooltip,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Composition,
  UpdateCompositionRequest,
} from '@texas/api/endpoints/compositionApi';
import { RefObject, useContext, useEffect, useState } from 'react';
import { CompositionView } from './composition/CompositionView';
import { CompositionOverview } from './composition/CompositionOverview';
import { useTranslation } from 'react-i18next';
import { useFormSubmitStore } from './hooks/useFormSubmit';
import { ViewState } from './types';
import { TemplateCard } from './composition/TemplateCard';
import { UpdateCompositionFormSubmitProvider } from './updateCompositionFormSubmitContext';
import { Icons } from '@texas/components/shared/Icons';
import { GroupContext } from './group/CompositionGroupView';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { VerifyDialogWithFunction } from '@texas/components/shared/dialog/VerifyDialogWithFunction';

export function TemplateView({
  composition,
  imageIdentifier,
  productGroupId,
  onLoaded,
  drawerRef,
}: {
  composition: Composition;
  imageIdentifier: string;
  productGroupId: number;
  onLoaded?: (template: Composition | null) => void;
  drawerRef?: RefObject<HTMLElement>;
}) {
  const { updateCompositionRequest, refetchGroup } = useContext(GroupContext)!;

  const [state, setState] = useState<ViewState>('view');

  useEffect(() => {
    if (!onLoaded) return;
    onLoaded(composition);
  }, [composition, onLoaded]);

  const formState = useFormSubmitStore({
    defaultValues: {
      weight: composition.weight,
      dimensions: composition.dimension?.dimensions.map((x) => ({
        id: x.id,
        value: x.value,
      })),
    },
    submit: (data: UpdateCompositionRequest) =>
      updateCompositionRequest(composition.id, data),
  });

  return (
    <Flex flexDir="column" gap={2} w="fit-content">
      <UpdateCompositionFormSubmitProvider value={formState}>
        <TemplateCard exists={!composition.archived} highlight={false}>
          <CardBody p={0}>
            <>
              {state === 'view' && (
                <CompositionView
                  imageIdentifier={imageIdentifier}
                  composition={composition}
                  archiveDisabled={true}
                />
              )}
              {state === 'edit' && (
                <Box p={2}>
                  <CompositionOverview
                    onUpdate={() => refetchGroup()}
                    drawerRef={drawerRef}
                    composition={composition}
                    productGroupId={productGroupId}
                    onClose={() => setState('view')}
                  />
                </Box>
              )}
            </>
          </CardBody>
        </TemplateCard>
      </UpdateCompositionFormSubmitProvider>
      <Footer
        composition={composition}
        state={state}
        stateChanged={(state) => setState(state)}
      />
    </Flex>
  );
}

function Footer({
  state,
  composition,
  stateChanged,
}: {
  state: ViewState;
  composition: Composition;

  stateChanged: (string: ViewState) => void;
}) {
  const { t } = useTranslation();
  const {
    archiveCompositionRequest,
    restoreCompositionRequest,
    componentSettings,
  } = useContext(GroupContext)!;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex gap={2} alignItems="center" justifyContent="space-between">
      {!composition.archived ? (
        <>
          {componentSettings?.compositionEdit !== 'hidden' && (
            <Button
              variant="texas-outline-light"
              w="full"
              onClick={() => {
                if (state === 'view') {
                  stateChanged('edit');
                  return;
                }
                stateChanged('view');
              }}
            >
              {state === 'view' ? (
                <>{t('composition.editTemplate')}</>
              ) : (
                <>{t('general.back')}</>
              )}
            </Button>
          )}

          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('composition.archiveComposition')}>
              <IconButton
                ml="auto"
                onClick={onOpen}
                variant="ghost"
                icon={<Icons.Archive />}
                aria-label="archive composition"
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Text>
            {t('general.archivedDate', {
              date: formatDate(Locale.En, composition.archived),
            })}
          </Text>
          {componentSettings?.compositionArchiveRestore !== 'hidden' && (
            <Tooltip label={t('general.restore')}>
              <IconButton
                onClick={() => restoreCompositionRequest(composition.id)}
                variant="ghost"
                icon={<Icons.ArchiveRemove />}
                aria-label="restore composition"
              />
            </Tooltip>
          )}
        </>
      )}
      <VerifyDialogWithFunction
        headerTitle={t('composition.archiveComposition')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        toPerform={() => archiveCompositionRequest(composition.id)}
        isOpen={isOpen}
        onClose={onClose}
        onSuccessTitle={t('tierPrice.tierDeleted')}
        onFailureTitle={t('tierPrice.failedToDeleteTier')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithFunction>
    </Flex>
  );
}
