import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Box,
  Text,
  FormControl,
  Flex,
  GridItem,
  SimpleGrid,
  ModalOverlay,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Tabs,
} from '@chakra-ui/react';
import { SharedDisclosureProps } from '@texas/components/shared/types';
import { cottonTcx } from '@texas/resources/pantone/tcx';
import { paperTpg } from '@texas/resources/pantone/tpg';
import React, { RefObject, useEffect, useRef } from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';
import { ColorBox } from '../shared/ColorBox';
import { useRegexSearch } from '@texas/components/shared/hooks/useRegexSearch';
import { SearchFilterInput } from '@texas/components/SearchFilterInput';

export interface PantoneColor {
  code: string;
  name: string;
  hex: string;
}

interface PantonePickerProps extends SharedDisclosureProps {
  onSelect: (colors: PantoneColor[]) => void;
  addedColorCodes: string[];
  drawerRef?: RefObject<HTMLElement>;
}

enum ColorType {
  Paper = 1,
  Cotton = 2,
}

export function PantonePicker({
  onSelect,
  isOpen,
  onClose,
  addedColorCodes,
  drawerRef,
}: PantonePickerProps) {
  return (
    <Modal
      portalProps={{ containerRef: drawerRef }}
      size="4xl"
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <InnerContent onSelect={onSelect} addedColorCodes={addedColorCodes} />
    </Modal>
  );
}

function InnerContent({
  addedColorCodes,
  onSelect,
}: {
  addedColorCodes: string[];
  onSelect: (colors: PantoneColor[]) => void;
}) {
  const { t } = useTranslation();
  const { search, setSearch, regexSearch } = useRegexSearch();
  const [pickedColors, setPickedColors] = useState<
    { colorType: ColorType; index: number }[]
  >(
    addedColorCodes.map((x) => {
      let index = paperTpg.colors.findIndex((c) => c.code === x);

      if (index !== -1) {
        return { colorType: ColorType.Paper, index };
      }

      index = cottonTcx.colors.findIndex((c) => c.code === x);

      return { colorType: ColorType.Cotton, index };
    }),
  );

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader>{t('composition.colorPicker')}</ModalHeader>
      <ModalBody>
        <FormControl pb={2}>
          <SearchFilterInput
            debounceDelay={0}
            autoFocus={true}
            placeholder={t('composition.searchColor')}
            value={search}
            onChange={(s) => setSearch(s)}
          />
        </FormControl>
        <Tabs variant="line">
          <TabList>
            <Tab>{t('composition.tcx')}</Tab>
            <Tab>{t('composition.tpg')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ColorGrid
                searchTerm={regexSearch}
                colors={cottonTcx.colors}
                pickedColors={pickedColors
                  .filter((x) => x.colorType === ColorType.Cotton)
                  .map((x) => x.index)}
                colorPicked={(index) => {
                  if (
                    pickedColors.some(
                      (x) =>
                        x.colorType === ColorType.Cotton && x.index === index,
                    )
                  ) {
                    setPickedColors((s) =>
                      s.filter(
                        (p) =>
                          p.colorType === ColorType.Cotton && p.index !== index,
                      ),
                    );
                    return;
                  }
                  setPickedColors((s) => [
                    ...s,
                    { colorType: ColorType.Cotton, index },
                  ]);
                }}
              />
            </TabPanel>
            <TabPanel>
              <ColorGrid
                searchTerm={regexSearch}
                colors={paperTpg.colors}
                pickedColors={pickedColors
                  .filter((x) => x.colorType === ColorType.Paper)
                  .map((x) => x.index)}
                colorPicked={(index) => {
                  if (
                    pickedColors.some(
                      (x) =>
                        x.colorType === ColorType.Paper && x.index === index,
                    )
                  ) {
                    setPickedColors((s) =>
                      s.filter(
                        (p) =>
                          p.colorType === ColorType.Paper && p.index !== index,
                      ),
                    );
                    return;
                  }
                  setPickedColors((s) => [
                    ...s,
                    { colorType: ColorType.Paper, index },
                  ]);
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ModalBody>
      <ModalFooter>
        <Flex gap={2} pr={2} flexWrap="wrap" justifyContent="end">
          {pickedColors.map((p, i) => {
            return (
              <ColorBox
                key={i}
                color={GetColor(pickedColors[i])}
                onRemove={() =>
                  setPickedColors((s) => s.filter((s) => s !== p))
                }
              />
            );
          })}
        </Flex>
        <Button
          minW="auto"
          onClick={() => {
            const selectedColors: PantoneColor[] = [];

            for (let i = 0; i < pickedColors.length; i++) {
              selectedColors.push(GetColor(pickedColors[i]));
            }

            onSelect(selectedColors);
          }}
        >
          {t('composition.selectColors')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

function ColorComponent({
  color,
  selected,
  onSelect,
}: {
  color: PantoneColor;
  selected: boolean;
  onSelect: () => void;
}) {
  return (
    <Box
      boxShadow="base"
      borderRadius="md"
      overflow="hidden"
      cursor="pointer"
      onClick={onSelect}
      borderColor="transparent"
      borderStyle="solid"
      borderWidth="2px"
      {...(selected ? { borderColor: 'white' } : null)}
    >
      <Box h="20" bgColor={`#${color.hex}`} />
      <Box bg="black" color="white" p={2}>
        <Text>{color.name}</Text>
        <Text fontSize="sm">{color.code}</Text>
      </Box>
    </Box>
  );
}

function ColorGrid({
  colors,
  searchTerm,
  pickedColors,
  colorPicked,
}: {
  colors: PantoneColor[];
  searchTerm: RegExp;
  pickedColors: number[];
  colorPicked: (id: number) => void;
}) {
  const ref = useRef<VirtuosoGridHandle>(null);
  const filteredColors = useMemo(() => {
    const colorsMatchingSearchTerm = colors.filter(
      (color) =>
        color.name.toLowerCase().match(searchTerm) ??
        color.code.toLowerCase().match(searchTerm),
    );

    return colorsMatchingSearchTerm;
  }, [searchTerm, colors]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollTo({ behavior: 'smooth', top: 0 });
  }, [searchTerm]);

  return (
    <Box position="relative" minH={20}>
      <VirtuosoGrid
        ref={ref}
        style={{ height: '400px' }}
        totalCount={filteredColors.length}
        components={{
          List: React.forwardRef(function List(props, listRef) {
            return (
              <SimpleGrid
                ref={listRef}
                columns={4}
                columnGap={2}
                rowGap={2}
                m={6}
                {...props}
              />
            );
          }),
          Item: ({ ...props }) => <GridItem {...props} overflow="hidden" />,
        }}
        itemContent={(index) => {
          const color = filteredColors[index];
          const colorIndex = colors.findIndex((x) => x.code === color.code);

          return (
            <ColorComponent
              color={color}
              selected={pickedColors.some((x) => x === colorIndex)}
              onSelect={() => {
                colorPicked(colorIndex);
              }}
            />
          );
        }}
      />
    </Box>
  );
}

function GetColor({
  colorType,
  index,
}: {
  colorType: ColorType;
  index: number;
}) {
  switch (colorType) {
    case ColorType.Paper:
      return paperTpg.colors[index];
    case ColorType.Cotton:
      return cottonTcx.colors[index];
  }
}

export function GetColorFromCode(code: string) {
  const pantone = paperTpg.colors.find((x) => x.code === code);

  if (pantone) return pantone;

  return cottonTcx.colors.find((x) => x.code === code);
}
