import { useDisclosure, useToast } from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  UpdateCompositionRequest,
  compositionApi,
} from '@texas/api/endpoints/compositionApi';
import { compositionGroupApi } from '@texas/api/endpoints/compositionGroupApi';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { ServerError } from '@texas/types';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface CompositionLocationData {
  variantId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
}

type ComponentState = 'hidden';

export interface CompositionGroupComponentSettings {
  expander?: ComponentState;
  compositionGroupDots?: ComponentState;
  compositionEdit?: ComponentState;
  compositionArchiveRestore?: ComponentState;
}

export function useCompositionGroup({
  variantId,
  cellX,
  cellY,
  supplierId,
  branchId,
  baseTemplateId,
  compositionGroupId,
  canBeReplaced,
  articleNodeGroup,
  onUpdated,
  onCompositionArchivedRestored,
  onlyArchived,
  componentSettings,
}: {
  variantId: number;
  cellX?: number;
  cellY?: number;
  supplierId?: number;
  branchId?: number;
  baseTemplateId: number | null;
  compositionGroupId?: number;
  canBeReplaced: boolean;
  articleNodeGroup?: ArticleNodeGroup;
  onUpdated?: () => void;
  onCompositionArchivedRestored?: () => void;
  onlyArchived?: boolean;
  componentSettings?: CompositionGroupComponentSettings;
}) {
  const toast = useToast();
  const { t } = useTranslation();
  const [groupId, setGroupId] = useState(compositionGroupId);
  const locationData = useRef<CompositionLocationData>({
    variantId,
    cellX,
    cellY,
    supplierId,
    branchId,
  });

  const [isPerformingApiCall, setIsPerformingApiCall] = useState(false);
  const {
    data: groupFromLocate,
    refetch: locateRefetch,
    loading: locateLoading,
    set: setFromLocate,
  } = useApiResource(compositionGroupApi.locateGroup);
  const {
    data: groupFromId,
    refetch: refetchGroupFromId,
    loading: loadingFromId,
    set: setFromId,
  } = useApiResource(compositionGroupApi.getGroup);

  const setGroup = groupFromLocate ? setFromLocate : setFromId;

  const refetchGroup = useCallback(() => {
    if (compositionGroupId) {
      refetchGroupFromId(
        compositionGroupId,
        onlyArchived ? { includeArchived: true } : undefined,
      );
      return;
    }
    locateRefetch({ variantId, branchId, supplierId, cellX, cellY });
  }, [
    branchId,
    cellX,
    cellY,
    compositionGroupId,
    onlyArchived,
    locateRefetch,
    refetchGroupFromId,
    supplierId,
    variantId,
  ]);

  useEffect(() => {
    refetchGroup();
  }, [refetchGroup]);

  useEffect(() => {
    if (!groupFromLocate?.exists) return;
    setGroupId(groupFromLocate.id);
  }, [groupFromLocate]);

  const copyDisclosure = useValueDisclosure<{
    fromCompositionGroupId: number;
    copyTitle: string;
  }>();
  const selectCellDisclosure = useDisclosure();

  const createGroupRequest = async () => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.addGroup,
      [{ variantId, cellX, cellY, supplierId, branchId }],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const restoreGroupRequest = async () => {
    if (!groupId) return;
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.restore,
      [groupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.restoreFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const archiveGroupRequest = async () => {
    if (!groupId) return;
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.archive,
      [groupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup(response);
        toast({
          title: t('general.successfullyArchived'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const addCompositionToGroupRequest = async () => {
    if (!groupId) return;
    setIsPerformingApiCall(true);
    await request(
      compositionApi.addComposition,
      [groupId],
      (response) => {
        setIsPerformingApiCall(false);
        setGroup((r) => ({
          ...r!,
          compositions: [...r!.compositions, response],
        }));
        toast({
          title: t('general.created'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.createFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const archiveCompositionRequest = async (id: number) => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.archiveComposition,
      [id],
      (response) => {
        setIsPerformingApiCall(false);
        if (onUpdated) onUpdated();
        setGroup((r) => ({
          ...r!,
          hasArchivedCompositions: true,
          compositions: r!.compositions.map((x) => {
            if (x.id === response.id) return response;
            return x;
          }),
        }));
        if (onCompositionArchivedRestored) onCompositionArchivedRestored();
        toast({
          title: t('general.archived'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.archiveFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const restoreCompositionRequest = async (id: number) => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.restoreComposition,
      [id],
      (response) => {
        if (onUpdated) onUpdated();
        setIsPerformingApiCall(false);
        setGroup((r) => ({
          ...r!,
          hasArchivedCompositions: r!.compositions.some(
            (c) => c.id !== response.id && c.archived,
          ),
          compositions: r!.compositions.map((x) => {
            if (x.id === response.id) return response;
            return x;
          }),
        }));
        if (onCompositionArchivedRestored) onCompositionArchivedRestored();
        toast({
          title: t('general.restored'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.restoreFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };
  const updateCompositionRequest = async (
    id: number,
    data: UpdateCompositionRequest,
  ) => {
    setIsPerformingApiCall(true);
    await request(
      compositionApi.updateComposition,
      [id, data],
      (_) => {
        if (onUpdated) onUpdated();
        setIsPerformingApiCall(false);
        refetchGroup();
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const copyCompositionGroupRequest = async (fromId: number) => {
    setIsPerformingApiCall(true);
    await request(
      compositionGroupApi.copyGroup,
      [fromId, locationData.current],
      (data) => {
        setIsPerformingApiCall(false);
        setGroup(data);
        toast({
          title: t('general.copied'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        setIsPerformingApiCall(false);
        toast({
          title: t('general.copyFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  };

  const compositions = () => {
    const c = groupFromId?.compositions ?? groupFromLocate?.compositions ?? [];

    return onlyArchived
      ? c.filter((x) => x.archived)
      : c.filter((x) => !x.archived);
  };

  return {
    group: groupFromId ?? groupFromLocate,
    compositions,
    locationData,
    loading: loadingFromId || locateLoading || isPerformingApiCall,
    baseTemplateId,
    canCopyFromTemplate: baseTemplateId !== null,
    canCopyFromCells: articleNodeGroup !== undefined,
    canBeReplaced,
    articleNodeGroup,
    refetchGroup,
    createGroupRequest,
    restoreGroupRequest,
    archiveGroupRequest,
    addCompositionToGroupRequest,
    copyCompositionGroupRequest,
    archiveCompositionRequest,
    restoreCompositionRequest,
    updateCompositionRequest,
    copyDisclosure,
    selectCellDisclosure,
    componentSettings,
  };
}
