import { bridgeConverter } from '../../bridge/bridgeConverter';
import { SearchArticles } from '../../react/components/searchArticles/SearchArticles';
import { NavigationBar } from '../../react/components/navigationBar/NavigationBar';
import { Sales } from '../../react/components/article/variant/sales/Sales';
import { AvailableBranches } from '../../react/components/configuration/branches/AvailableBranches';
import { UserProfiles } from '../../react/components/users/keyAccountManager/UserProfiles';
import { ProjectContainer } from '../../react/components/customer/projects/ProjectContainer';
import { SaveAndSync } from '../../react/components/article/variant/saveAndSync/SaveAndSync';
import { Dashboard } from '../../react/components/dashboard/Dashboard';
import { Treatments } from '../../react/components/configuration/treatments/Treatments';
import { Customer } from '../../react/components/customer/Customer';
import { DimensionGroups } from '../../react/components/configuration/dimensionGroups/DimensionGroups';
import { VariantSuppliers } from '../../react/components/article/variant/suppliers/VariantSuppliers';
import { AngularAddSupplier } from '../../react/components/article/variant/suppliers/AngularAddSupplier';
import { Qualities } from '../../react/components/configuration/qualities/Qualities';
import { ProductGroups } from '../../react/components/configuration/productGroups/ProductGroups';
import { Contacts } from '../../react/components/customer/contacts/Contacts';
import { General } from '../../react/components/article/variant/general/General';
import { Compositions } from '../../react/components/article/variant/compositions/Compositions';
import { ProductApprovalLockedMessage } from '../../react/components/shared/productApproval/LockedMessage';
import { CreateProductApprovalButton } from '../../react/components/article/productApproval/create/CreateProductApprovalButton';
import { ProductApprovals } from '../../react/components/article/productApproval/ProductApprovals';
import { NewSupplier } from '../../react/components/suppliers/newSupplier/newSupplier';

angular
  .module('app.bridgeComponents', [])
  .component('searchArticles', bridgeConverter(SearchArticles, []))
  .component('contacts', bridgeConverter(Contacts, ['customerId']))
  .component(
    'sales',
    bridgeConverter(Sales, [
      'articleId',
      'variantId',
      'customerId',
      'articleNodeGroup',
    ]),
  )
  .component('availableBranches', bridgeConverter(AvailableBranches, []))
  .component('userProfiles', bridgeConverter(UserProfiles, ['userId']))
  .component('navBar', bridgeConverter(NavigationBar, []))
  .component(
    'saveAndSync',
    bridgeConverter(SaveAndSync, [
      'variantId',
      'isMain',
      'articleId',
      'syncedVariantId',
    ]),
  )
  .component(
    'createProductApproval',
    bridgeConverter(CreateProductApprovalButton, [
      'variantId',
      'articleNodeGroups',
      'customerId',
    ]),
  )
  .component(
    'projectContainer',
    bridgeConverter(ProjectContainer, [
      'customerId',
      'brandName',
      'identifier',
      'keyAccountManagerId',
      'customerArchived',
    ]),
  )
  .component('treatments', bridgeConverter(Treatments, []))
  .component('customer', bridgeConverter(Customer, ['customerId', 'conceptId']))
  .component(
    'variantSuppliers',
    bridgeConverter(VariantSuppliers, ['variantId']),
  )
  .component('angularAddSupplier', bridgeConverter(AngularAddSupplier, []))
  .component('qualities', bridgeConverter(Qualities, []))
  .component('productGroups', bridgeConverter(ProductGroups, []))
  .component('dimensionGroups', bridgeConverter(DimensionGroups, []))
  .component('dashboard', bridgeConverter(Dashboard, []))
  .component('newSupplier', bridgeConverter(NewSupplier, []))
  .component('general', bridgeConverter(General, ['id']))
  .component(
    'compositions',
    bridgeConverter(Compositions, [
      'variantId',
      'productGroupId',
      'articleNodeGroup',
    ]),
  )
  .component(
    'lockedMessage',
    bridgeConverter(ProductApprovalLockedMessage, [
      'variantId',
      'branchId',
      'matrixNodeXId',
      'matrixNodeYId',
      'isOnlyInfo',
    ]),
  )
  .component(
    'productApproval',
    bridgeConverter(ProductApprovals, ['articleId']),
  );
