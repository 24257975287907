import { useColorMode } from '@chakra-ui/react';
import {
  SelectBorderStyle,
  reactSelectStyle,
  reactSelectTheme,
} from '@texas/resources/styles';
import Select, { GroupBase, Props } from 'react-select';

export interface TexasSelectProps<
  ReactSelectType,
  IsMulti extends boolean = false,
> extends Props<ReactSelectType, IsMulti, GroupBase<ReactSelectType>> {
  borderStyle?: SelectBorderStyle;
  isAngularTheme?: boolean;
}

export function TexasSelect<ReactSelectType, IsMulti extends boolean = false>(
  props: TexasSelectProps<ReactSelectType, IsMulti>,
) {
  const { colorMode } = useColorMode();

  return (
    <Select
      {...props}
      styles={reactSelectStyle<IsMulti, ReactSelectType>({
        ...props,
        mode: colorMode,
      })}
      theme={(theme) => reactSelectTheme(theme, colorMode)}
    />
  );
}
