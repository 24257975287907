import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = config.definePartsStyle({
  container: {
    borderRadius: 'md',
    _light: { backgroundColor: 'white' },
  },
  body: {
    borderRadius: 'md',
    _light: { backgroundColor: 'white' },
  },
});

const variants = {
  'composition-template': {
    container: {
      border: '1px solid',
      background: 'transparent',
    },
    body: {
      _light: { backgroundColor: 'transparent' },
    },
  },
  dark: {
    container: {
      backgroundColor: 'texas.bg.dark',
      _light: { backgroundColor: 'white' },
    },
    body: {
      backgroundColor: 'texas.bg.dark',
      _light: { backgroundColor: 'white' },
    },
  },
  'dark-border': {
    container: {
      backgroundColor: 'texas.bg.dark',
      border: '1px solid',
      borderColor: 'gray.900',
      _light: { backgroundColor: 'white', border: 'none' },
    },
    body: {
      backgroundColor: 'texas.bg.dark',
      _light: { backgroundColor: 'white' },
    },
  },
  brand: {
    container: {
      pos: 'relative',
      overflow: 'hidden',
      minW: '120px',
      maxW: '220px',
      height: '100%',
    },
    body: {
      bg: 'texas.bg.900',
      _hover: { bg: 'texas.bg.800' },
      _light: { _hover: { bg: 'rgba(0, 0, 0, 0.02)' } },
    },
  },
};

export default config.defineMultiStyleConfig({
  baseStyle,
  variants,
});
