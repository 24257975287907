import { useMemo, useState } from 'react';
import { SearchFilterInput } from '../SearchFilterInput';
import {
  Box,
  Flex,
  Button,
  VStack,
  Collapse,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  MenuDivider,
  MenuGroup,
} from '@chakra-ui/react';
import { RowSelectionState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { defaultEmptyFilters, useSearchArticles } from './useSearchArticles';
import { ArticleSearchProps } from './types';
import { queryStringToObject } from '@texas/utils/helpers/commonHelpers';
import { reactEvents } from 'src/bridge/reactEvents';
import { filters } from '../filter/filters';
import { MoveArticles } from '../shared/MoveArticles';
import { VariantsSearchQuery } from '@texas/api/endpoints/searchApi';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { AddFilter } from '../filter/AddFilter';
import { Icons } from '../shared/Icons';
import { DataTableContainer } from '../shared/dataTable/DataTableContainer';
import { useTexasDrawer, TexasDrawer } from '../shared/drawer/TexasDrawer';
import { TexasDrawerHeader } from '../shared/drawer/TexasDrawerHeader';
import { VerifyButton } from '../shared/verifyButton/VerifyButton';
import { Container } from '../shared/Container';
import { TableColumns } from './TableColumns';
import {
  defaultPageSize,
  defaultPage,
} from '../shared/dataTable/defaultTableOptions';
import { ArticlesDataTableContainerFooter } from '../shared/dataTable/ArticlesDataTableContainerFooter';
import { useExportableArticles } from './useExportableArticles';
import { TexasDrawerContent } from '../shared/drawer/TexasDrawerContent';
import { ChangeState } from '../shared/ChangeState';
import { UpdateVariant } from '@texas/api/endpoints/articlesApi';

// Texas backend excel generation is too slow to handle too many articles
// until we have reworked the functionality we need to cap the amount
export const maxExportArticlesAmount = 200;

const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: '',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: undefined,
};

const defaultSearchPage: ArticleSearchProps = {
  filters: [],
  searchParams: defaultSearchParams,
};

interface RowData {
  articleId: number;
  variantId: number;
  branchId: number;
}

export function SearchArticles() {
  const { t } = useTranslation();

  const {
    articles,
    searchPage,
    searchParamsRef,
    filterElements,
    activeFilters,
    error,
    loading,
    setSearchPage,
    setSearchParamsWithRef,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    refetchArticles,
    availableFilters,
  } = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: defaultSearchPage,
    defaultPage: defaultPage,
    defaultSearchParams: defaultSearchParams,
    overrideDefaultArticleSearchPage: searchPagePropsFromQueryString(),
    localStorageKey: 'articleSearchPage',
    limit: defaultPageSize,
  });

  const columns = useMemo(() => {
    return TableColumns(t);
  }, [t]);

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  const {
    value: moveArticlesValue,
    isOpen: isMoveArticlesOpen,
    onOpen: onMoveArticlesOpen,
    onClose: onMoveArticlesClose,
  } = useValueDisclosure<number[]>();

  const {
    value: changeStateValue,
    isOpen: isChangeStateOpen,
    onOpen: onChangeStateOpen,
    onClose: onChangestateClose,
  } = useValueDisclosure<UpdateVariant[]>();

  const drawer = useTexasDrawer(ModalState.Closed);
  return (
    <>
      <TexasDrawer modal={drawer} ref={drawer.ref}>
        <TexasDrawerContent>
          <TexasDrawerHeader title={t('searchArticles.addFilter')} />
          <AddFilter
            availableFilters={availableFilters}
            onAdd={(type) => {
              setActiveFiltersVariantResults((s) => [
                ...s,
                {
                  optionType: type,
                  ...defaultEmptyFilters,
                  isPopulated: false,
                },
              ]);
              setActiveFilterTypes((s) => [...s, [true, type]]);
              setSearchPage((s) => ({
                ...s,
                filters: [...s.filters, type],
              }));
            }}
          />
        </TexasDrawerContent>
      </TexasDrawer>
      <MoveArticles
        onArticlesMoved={() => {
          setSelectedRows({});
          refetchArticles();
        }}
        isOpen={isMoveArticlesOpen}
        onClose={onMoveArticlesClose}
        articles={moveArticlesValue}
      />
      <ChangeState
        onStateChanged={() => {
          setSelectedRows({});
          refetchArticles();
        }}
        isOpen={isChangeStateOpen}
        onClose={onChangestateClose}
        variants={changeStateValue}
      />
      <Container title={t('searchArticles.searchArticles')}>
        <VStack alignItems="start" px={4}>
          <Box mt={2} mb={4} width="100%">
            <SearchFilterInput
              placeholder={t('searchArticles.itemcodeOrDescription')}
              value={searchPage.searchParams.searchTerm}
              onChange={(searchTerm) =>
                setSearchParamsWithRef({
                  ...searchParamsRef.current,
                  searchTerm,
                  page: defaultPage,
                })
              }
            />
          </Box>
          <HStack w="100%" mt="0 !important">
            <Collapse in={activeFilters.length > 0}>
              <VerifyButton
                buttonProps={{
                  variant: 'texas-outline-light',
                  size: 'sm',
                }}
                onVerified={() => {
                  setActiveFilterTypes([]);
                  setActiveFiltersVariantResults((f) =>
                    f.filter((f2) => f2.optionType !== undefined),
                  );
                  setSearchParamsWithRef(defaultSearchPage.searchParams);
                  setSearchPage((s) => ({ ...s, filters: [] }));
                }}
                label={t('searchArticles.clearAllFilters')}
              />
            </Collapse>
            <Button
              variant="texas-light"
              size="sm"
              marginLeft="auto !important"
              onClick={() => drawer.setModalState(ModalState.Open)}
            >
              {t('searchArticles.addFilter')}
            </Button>
          </HStack>

          <Box
            width="100%"
            display="grid"
            gridTemplateColumns="repeat(6, 1fr)"
            gridGap={2}
            rowGap={4}
          >
            {filterElements}
          </Box>
        </VStack>

        <Flex
          backgroundColor="gray.600"
          _light={{ backgroundColor: 'gray.50' }}
          mt={4}
          py={2}
          px={4}
          alignItems="center"
          justify="space-between"
        >
          <Text>{t('general.articles')}</Text>
          <Menu>
            <MenuButton
              as={IconButton}
              size="sm"
              icon={<Icons.DotsHorizontal />}
              aria-label="Options"
              variant="texas-light"
            />
            <MenuList>
              <MenuItem
                isDisabled={
                  Object.keys(selectedRows).length === 0 ||
                  Object.keys(selectedRows).length > maxExportArticlesAmount
                }
                onClick={() => {
                  reactEvents.excelExportClicked.dispatch({
                    articles: exportableArticles,
                    branchIds: searchPage.searchParams['branchIds'] ?? [],
                    hideBranchIds: true,
                  });
                }}
              >
                {t('general.exportExcel')}
              </MenuItem>
              <MenuDivider />
              <MenuGroup title={t('general.actions')}>
                <MenuItem
                  isDisabled={Object.keys(selectedRows).length === 0}
                  onClick={() => {
                    onMoveArticlesOpen(
                      Object.keys(selectedRows).map((x) => {
                        const data: RowData = JSON.parse(x);
                        return data.articleId;
                      }),
                    );
                  }}
                >
                  {t('article.moveArticles')}
                </MenuItem>
                <MenuItem
                  isDisabled={Object.keys(selectedRows).length === 0}
                  onClick={() => {
                    onChangeStateOpen(
                      Object.keys(selectedRows).map((x) => {
                        const data: RowData = JSON.parse(x);
                        const updateVariant: UpdateVariant = {
                          variantId: data.variantId,
                          branchId: data.branchId,
                        };
                        return updateVariant;
                      }),
                    );
                  }}
                >
                  {t('article.changeState')}
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Flex>
        <DataTableContainer
          p={4}
          css={{ ' th, td': { whiteSpace: 'normal' } }}
          error={error}
          datatable={{
            selectionProps: {
              setSelectedRows: setSelectedRows,
              selectedRows: selectedRows,
            },
            rowProps: {
              getRowId: (originalRow, _) => {
                const rowData: RowData = {
                  articleId: originalRow.articleId,
                  variantId: originalRow.variantId,
                  branchId: originalRow.branchId,
                };
                return JSON.stringify(rowData);
              },
            },
            data: articles?.pagedItems.items ?? [],
            columns: columns,
            isLoading: loading,
            sorted: {
              onSortedChange: ({ key, desc }) => {
                setSearchParamsWithRef({
                  ...searchParamsRef.current,
                  sortBy: key,
                  sortDesc: desc,
                  page: defaultPage,
                });
              },
              key: searchPage.searchParams['sortBy'],
              desc: searchPage.searchParams['sortDesc'],
            },
          }}
          pagination={{
            totalItems: articles?.pagedItems.totalItems ?? 0,
            pageSize: defaultPageSize,
            currentPage: searchPage.searchParams.page,
            onPageChange: (page) =>
              setSearchParamsWithRef({
                ...searchParamsRef.current,
                page,
              }),
          }}
          footer={
            <ArticlesDataTableContainerFooter
              selectedRows={Object.keys(selectedRows).length}
              onClear={() => setSelectedRows({})}
            />
          }
        />
      </Container>
    </>
  );

  function searchPagePropsFromQueryString(): ArticleSearchProps | undefined {
    const searchQueryParams: VariantsSearchQuery | undefined =
      queryStringToObject();

    if (!searchQueryParams) return;

    const mappedFilters = filters
      .filter((f) => searchQueryParams[f.queryParamsKey] !== undefined)
      .map((f) => f.optionType);

    return {
      filters: mappedFilters,
      searchParams: searchQueryParams,
    };
  }
}
