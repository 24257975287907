import {
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  useColorMode,
} from '@chakra-ui/react';
import {
  profitColor,
  evaluateProfit,
  roundedPurchasePriceWithFees,
  setValueAsDecimalExtender,
} from '../shared';
import { useEffect } from 'react';
import { SalesContext } from '../Sales';
import { useTranslation } from 'react-i18next';
import { UpdateHistory } from '@texas/components/shared/autoUpdate/history/UpdateHistory';
import {
  AutoUpdateResponseCallback,
  Position,
} from '@texas/components/shared/autoUpdate/types';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import {
  exchangeRateCalculation,
  getRoundedPurchasePriceWithFees,
} from '@texas/utils/helpers/numberHelpers';
import { MarginCalculation } from '../MarginCalculation';
import { articleHistoryApi } from '@texas/api/endpoints/articleHistoryApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import { SalesCurrency } from '@texas/api/endpoints/salesCurrenciesApi';
import {
  TierPrice,
  AutoUpdateSalesPrice,
  SalesPrice,
  AutoUpdateSalesPriceAdditionalData,
  tierPriceApi,
  EMPTY_AUTO_UPDATE_SALES_PRICE_OBJECT,
} from '@texas/api/endpoints/tierPriceApi';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { defaultNumberOfDecimals } from '@texas/resources/constants';

interface SalesPriceForm {
  tierPrice: TierPrice;
  salesCurrency: SalesCurrency;
  purchaseInformation: PurchaseInformation;
  currencyBuyingRate: number;
  selectedCurrencyCode: string;
  showCurrencyCode?: boolean;
  isDisabled?: boolean;
  onPriceChanged?: (price: number) => void;
}

export function SalesPriceForm({
  showCurrencyCode = true,
  tierPrice,
  salesCurrency,
  purchaseInformation,
  currencyBuyingRate,
  selectedCurrencyCode,
  isDisabled = false,
  onPriceChanged = undefined,
}: SalesPriceForm) {
  const { colorMode } = useColorMode();
  const salesPrice = tierPrice.salesPrices.find(
    (s) => s.salesCurrencyId === salesCurrency.id,
  );
  const basePrice = tierPrice.basePrices.find(
    (b) => b.supplierId === purchaseInformation.supplierId,
  );

  const salesContext = useActiveContext(SalesContext);
  const { t } = useTranslation();

  const {
    registerAutoUpdate,
    watch,
    form,
    getValues,
    resetValue,
    setValueId,
    triggerSubmit,
    register,
  } = useAutoUpdateForm<
    AutoUpdateSalesPrice,
    SalesPrice,
    AutoUpdateSalesPriceAdditionalData
  >({
    defaultValue: {
      price: salesPrice?.price,
    },
    valueId: salesPrice?.id ?? 0,
    namePrefix: 'tierPrice',
    autoUpdateRequest: tierPriceApi.autoUpdateSalesPrice,
    fallbackMapObject: EMPTY_AUTO_UPDATE_SALES_PRICE_OBJECT,
    additionalData: {
      tierPriceId: tierPrice.id,
      salesCurrencyId: salesCurrency.id,
    },
    onResponse,
  });

  useEffect(() => {
    const salesPriceValue = salesPrice?.price;
    if (salesPriceValue !== getValues('price')) {
      resetValue('price', salesPrice?.price);
    }
    if (salesPrice) {
      setValueId(salesPrice.id);
    }
  }, [salesPrice, getValues, resetValue, setValueId]);

  function onResponse(
    data: AutoUpdateResponseCallback<SalesPrice, AutoUpdateSalesPrice>,
  ) {
    const toSet =
      salesContext.tierPrices.data?.map((t) => {
        if (t.id === tierPrice.id) {
          if (
            !t.salesPrices.find((s) => s.id === data.unmodifiedServerData.id)
          ) {
            t.salesPrices.push(data.unmodifiedServerData);
            return t;
          }

          t.salesPrices = t.salesPrices.map((s) => {
            if (s.id === data.unmodifiedServerData.id) {
              return { ...s, ...data.mappedServerData };
            }

            return s;
          });
        }

        return t;
      }) ?? [];

    setValueId(data.unmodifiedServerData.id);

    salesContext.tierPrices.set(toSet);
  }

  const watchPrice = watch('price');

  useEffect(() => {
    if (!onPriceChanged) return;
    onPriceChanged(watchPrice ?? 0);
  }, [onPriceChanged, watchPrice]);

  const priceProfit = evaluateProfit(
    exchangeRateCalculation(
      watchPrice ?? 0,
      salesCurrency.fixedExchangeRate,
      currencyBuyingRate,
      defaultNumberOfDecimals,
    ),
    roundedPurchasePriceWithFees(
      basePrice?.price ?? 0,
      currencyBuyingRate,
      purchaseInformation,
    ),
  );

  const color = profitColor(priceProfit, colorMode === 'dark');

  return (
    <form style={{ width: 'fit-content' }} {...form}>
      <fieldset>
        <AutoUpdateWrapper
          autoUpdateProps={registerAutoUpdate}
          path="price"
          position={Position.Right}
        >
          <InputGroup
            alignItems="center"
            padding={1}
            pr={0}
            bg="texas.bg.800"
            _light={{ bg: 'gray.10' }}
            w={56}
            borderRadius={4}
          >
            <Input
              {...register('price', {
                valueAsNumber: true,
                setValueAs: (value) => setValueAsDecimalExtender(value, true),
                onBlur: triggerSubmit,
              })}
              color={color}
              role="group"
              _placeholder={{
                color: color,
              }}
              placeholder="0"
              disabled={isDisabled}
              type="number"
            />
            {showCurrencyCode && (
              <Flex gap={1}>
                <InputRightAddon>{salesCurrency.currencyCode}</InputRightAddon>
                <InputRightAddon>
                  <UpdateHistory
                    header={t('history.salesPriceHistory')}
                    historyRequestWrapper={() =>
                      articleHistoryApi.salesPrice(
                        salesContext.articleId,
                        tierPrice.id,
                        salesCurrency.id,
                      )
                    }
                  />
                  <MarginCalculation
                    purchasePrice={getRoundedPurchasePriceWithFees(
                      basePrice?.price ?? 0,
                      purchaseInformation.freightCostValue,
                      purchaseInformation.handlingFeeValue,
                      5,
                    )}
                    purchaseExchangeRate={purchaseInformation.fixedExchangeRate}
                    salesPrice={watchPrice ?? 0}
                    salesExchangeRate={salesCurrency.fixedExchangeRate}
                    targetExchangeRate={currencyBuyingRate}
                    targetCurrencyCode={selectedCurrencyCode}
                    profit={priceProfit}
                  />
                </InputRightAddon>
              </Flex>
            )}
          </InputGroup>
        </AutoUpdateWrapper>
      </fieldset>
    </form>
  );
}
