import { Button, Flex, Text } from '@chakra-ui/react';
import { VariantBranchSupplier } from '@texas/api/endpoints/variantsApi';
import { useTranslation } from 'react-i18next';

export function SupplierCell({
  supplier,
  onRemove,
}: {
  supplier: VariantBranchSupplier;
  onRemove: (supplierId: number, branchId: number) => void;
}) {
  const { t } = useTranslation();
  return (
    <Flex direction="column" grow={1} py={2} align="start">
      <Text px={4}>{supplier.supplierName}</Text>
      <Button
        mt="auto"
        ml={2}
        size="xs"
        variant="texas-link-danger"
        visibility="hidden"
        _groupHover={{ visibility: 'visible' }}
        onClick={() => onRemove(supplier.supplierId, supplier.branchId)}
      >
        {t('general.remove')}
      </Button>
    </Flex>
  );
}
