import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = config.definePartsStyle({
  tablist: {
    color: 'whiteAlpha.600',
    borderBottomColor: 'whiteAlpha.400',
    _light: {
      color: 'gray.300',
      borderBottomColor: 'blackAlpha.200',
    },
  },
  tab: {
    _selected: {
      borderBottomColor: 'texas.sand.100 !important',
      color: 'white',
    },
    _light: {
      color: 'gray.300',
      _selected: {
        color: 'texas.bg.900',
        borderBottomColor: 'texas.burntSienna.500 !important',
      },
    },
  },
});

export default config.defineMultiStyleConfig({
  defaultProps: { variant: 'line' },
  baseStyle,
});
