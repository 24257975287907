import { useCallback, useMemo, useState } from 'react';
import { Step, StepConfig } from '../types';
import { useStepStates } from './useStepStates';

interface WizardStoreProps<TData> {
  stepConfigs: StepConfig[];
  submitHandler: (data: TData) => Promise<void>;
  defaultData: TData;
  defaultSummary: React.ReactNode | null;
}

export function useWizardStateStore<TData>({
  stepConfigs,
  submitHandler,
  defaultData,
  defaultSummary,
}: WizardStoreProps<TData>) {
  const {
    stepStates,
    setStepIsActive,
    setStepIsValid,
    nextStep,
    resetStep,
    resetAllSteps,
  } = useStepStates(stepConfigs);

  const [data, setData] = useState<TData>(defaultData);

  const [initalized, setInitialized] = useState(false);

  const steps = useMemo(
    () =>
      stepConfigs.map<Step>((c) => ({
        id: c.id,
        config: c,
        state: stepStates.find((s) => s.id === c.id)!,
        last: c.last,
      })),
    [stepConfigs, stepStates],
  );

  const submit = useCallback(async () => {
    await submitHandler(data);

    setData(defaultData);
    resetAllSteps();
    setStepIsActive(stepStates[0]!.id);
  }, [
    data,
    defaultData,
    resetAllSteps,
    setStepIsActive,
    stepStates,
    submitHandler,
  ]);

  function init() {
    if (initalized) return;
    setInitialized(true);
    setStepIsActive(stepStates[0]!.id);
  }

  const summary = useMemo(() => {
    const activeStep = steps.find((x) => x.state.isActive);

    if (!activeStep) {
      return defaultSummary;
    }

    return activeStep.config.customSummary ?? defaultSummary;
  }, [defaultSummary, steps]);

  return {
    stepId: stepStates.find((x) => x.isActive)?.id ?? '',
    steps,
    stepStates,
    stepConfigs,
    setStepIsActive,
    setStepIsValid,
    nextStep,
    resetStep,
    init,
    submit,
    setData,
    data,
    summary,
  };
}
