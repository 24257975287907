import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { modalAnatomy } from '@chakra-ui/anatomy';

const config = createMultiStyleConfigHelpers(modalAnatomy.keys);

const baseStyle = config.definePartsStyle({
  header: {
    color: 'white',
    _light: { color: 'black' },
  },
  dialog: {
    background: 'texas.bg.900',
    _light: { background: 'white' },
  },
  dialogContainer: {
    color: 'white',
    _light: { color: 'texas.bg.900' },
  },
});

export default config.defineMultiStyleConfig({ baseStyle });
