import { Box, Skeleton, Button, Flex } from '@chakra-ui/react';
import { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SalesInformationBranch } from './SalesInformationBranch';
import { reactEvents } from 'src/bridge/reactEvents';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  CustomerCurrency,
  currenciesApi,
} from '@texas/api/endpoints/currenciesApi';
import {
  PurchaseInformation,
  purchaseInformationApi,
} from '@texas/api/endpoints/purchaseInformationApi';
import {
  SalesInformation,
  salesInformationApi,
} from '@texas/api/endpoints/salesInformationApi';
import { ExportArticleBranchItem } from '@texas/api/endpoints/searchApi';
import { TierPrice, tierPriceApi } from '@texas/api/endpoints/tierPriceApi';
import { VatCode, vatCodesApi } from '@texas/api/endpoints/vatCodesApi';
import { ApiResource, useApiResource } from '@texas/api/hooks/useApiResource';
import { AlertDetails } from '@texas/components/shared/alert/AlertDetails';
import { ProductApprovalLockedMessage } from '@texas/components/shared/productApproval/LockedMessage';

interface SalesPageContext {
  articleId: number;
  variantId: number;
  salesInformation: ApiResource<[variantId: number], SalesInformation[]>;
  purchaseInformation: ApiResource<[variantId: number], PurchaseInformation[]>;
  vatCodes: ApiResource<[], VatCode[]>;
  currencies: ApiResource<[customerId: number], CustomerCurrency[]>;
  tierPrices: ApiResource<[variantId: number], TierPrice[]>;
  articleNodeGroup: ArticleNodeGroup;
}

export const SalesContext = createContext<SalesPageContext | null>(null);

interface SalesProps {
  articleId: number;
  variantId: number;
  customerId: number;
  articleNodeGroup: ArticleNodeGroup;
}

export function Sales(props: SalesProps) {
  const { t } = useTranslation();

  const salesInfoResource = useApiResource(
    salesInformationApi.getSalesInformations,
  );
  const purchaseInfoResource = useApiResource(
    purchaseInformationApi.getPurchaseInformationsFromVariant,
  );
  const vatCodesResource = useApiResource(vatCodesApi.getAllVatCodes);
  const currenciesResource = useApiResource(
    currenciesApi.getAllCurrenciesMergedWithCustomerCurrencies,
  );
  const tierPricesResource = useApiResource(
    tierPriceApi.getTierPricesFromVariant,
  );

  const {
    salesInfoRefetch,
    purchaseInfoRefetch,
    vatCodesRefetch,
    currenciesRefetch,
    tierPricesRefetch,
  } = {
    salesInfoRefetch: salesInfoResource.refetch,
    purchaseInfoRefetch: purchaseInfoResource.refetch,
    vatCodesRefetch: vatCodesResource.refetch,
    currenciesRefetch: currenciesResource.refetch,
    tierPricesRefetch: tierPricesResource.refetch,
  };

  useEffect(() => {
    salesInfoRefetch(props.variantId);
    purchaseInfoRefetch(props.variantId);
    vatCodesRefetch();
    currenciesRefetch(props.customerId);
    tierPricesRefetch(props.variantId);
  }, [
    props.variantId,
    props.customerId,
    salesInfoRefetch,
    purchaseInfoRefetch,
    vatCodesRefetch,
    currenciesRefetch,
    tierPricesRefetch,
  ]);

  return (
    <SalesContext.Provider
      value={{
        articleId: props.articleId,
        variantId: props.variantId,
        salesInformation: salesInfoResource,
        purchaseInformation: purchaseInfoResource,
        vatCodes: vatCodesResource,
        currencies: currenciesResource,
        tierPrices: tierPricesResource,
        articleNodeGroup: props.articleNodeGroup,
      }}
    >
      <Box overflow="hidden">
        <Flex gap={2}>
          <Button
            variant="texas"
            onClick={() =>
              reactEvents.excelExportClicked.dispatch({
                branchIds: salesInfoResource.data?.map((s) => s.branchId) ?? [],
                articles: [
                  {
                    id: props.articleId,
                    customerId: props.customerId,
                    branches:
                      salesInfoResource.data?.map<ExportArticleBranchItem>(
                        (s) => {
                          return { id: s.branchId };
                        },
                      ) ?? [],
                  },
                ],
                hideBranchIds: false,
                template: 3,
              })
            }
          >
            {t('general.exportExcel')}
          </Button>
          <Box px={4} mb={-30}>
            <ProductApprovalLockedMessage
              variantId={props.variantId}
              isOnlyInfo={true}
            />
          </Box>
        </Flex>

        <Skeleton minH={64} isLoaded={!salesInfoResource.loading}>
          <Flex
            direction="column"
            alignItems="start"
            backgroundColor="texas.bg.light"
            gap={4}
            pt={4}
          >
            {currenciesResource.data !== null && (
              <>
                {salesInfoResource.data && purchaseInfoResource.data && (
                  <>
                    {purchaseInfoConfigured(purchaseInfoResource.data) ? (
                      <>
                        {salesInfoResource.data.map((s) => (
                          <SalesInformationBranch
                            key={s.id}
                            salesInfo={s}
                            purchaseInfo={purchaseInfoResource.data?.find(
                              (p) => p.isMain && p.branchId === s.branchId,
                            )}
                            currencies={
                              currenciesResource.data?.filter(
                                (c) => c.branchId === s.branchId,
                              ) ?? []
                            }
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <AlertDetails
                          variant="solid"
                          title={t('variant.purchase.noPurchaseInfoConfigured')}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Flex>
        </Skeleton>
      </Box>
    </SalesContext.Provider>
  );
}

function purchaseInfoConfigured(purchaseInfo: PurchaseInformation[]) {
  return purchaseInfo.filter((p) => p.supplierId).length > 0;
}
