import { Flex, Text } from '@chakra-ui/react';
import {
  formatNodeGroup,
  nodeGroupIsPopulated,
} from '@texas/utils/helpers/nodeGroupHelpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GeneratedProductApprovalState } from '../../../types';
import { useProdutApprovalWizardState } from '../../../context';

export function WizardSummary() {
  const { data } = useProdutApprovalWizardState();
  const { t } = useTranslation();

  return (
    <Flex gap={4}>
      <SummaryRow
        label={t('wizard.productApproval.selectedBranches')}
        data={data.selectedBranches}
      />
      <SummaryRow
        label={t('wizard.productApproval.selectedSuppliers')}
        data={data.selectedSuppliers.map((x) => ({
          name: `${x.name} (${x.branch.identifier})`,
        }))}
      />
      {nodeGroupIsPopulated(data.articleNodes) && (
        <SummaryRow
          label={t('wizard.productApproval.selectedCells')}
          data={Object.keys(data.selectedCells)
            .filter((key) => data.selectedCells[key].added)
            .map((key) => ({
              name: formatNodeGroup(
                data.articleNodes!.nodeXValues[data.selectedCells[key].xIndex],
                data.articleNodes!.nodeYValues[data.selectedCells[key].yIndex],
              ),
            }))}
        />
      )}
      <SummaryRow label={t('wizard.productApproval.selectedProductApprovals')}>
        {data.generatedProductApprovalKeys.length > 0 && (
          <Flex flexDir="column">
            <Text>
              {t('wizard.productApproval.countOk', {
                count: data.generatedProductApprovalKeys.filter(
                  (k) =>
                    data.generatedProductApprovals[k].state ===
                    GeneratedProductApprovalState.Accepted,
                ).length,
              })}
            </Text>
            <Text>
              {t('wizard.productApproval.ignored', {
                count: data.generatedProductApprovalKeys.filter(
                  (k) =>
                    data.generatedProductApprovals[k].state ===
                    GeneratedProductApprovalState.Ignored,
                ).length,
              })}
            </Text>
            <Text>
              {t('wizard.productApproval.unhandled', {
                count: data.generatedProductApprovalKeys.filter(
                  (k) =>
                    data.generatedProductApprovals[k].state ===
                    GeneratedProductApprovalState.Unhandled,
                ).length,
              })}
            </Text>
          </Flex>
        )}
      </SummaryRow>
    </Flex>
  );
}

function SummaryRow({
  label,
  data,
  children,
}: {
  label: string;
  data?: { name: string }[];
  children?: React.ReactNode;
}) {
  return (
    <Flex flexDir="column">
      <Text variant="subtitle">{label}</Text>
      {children && <>{children}</>}
      {data && (
        <>
          {data.map((x, i) => (
            <Text key={i}>{x.name}</Text>
          ))}
        </>
      )}
    </Flex>
  );
}
