import { Box } from '@chakra-ui/react';

export function TexasDrawerStrip({ children }: { children: React.ReactNode }) {
  return (
    <Box
      h="full"
      pos="relative"
      top={0}
      bottom={0}
      left={0}
      bg="white"
      borderLeftRadius={16}
      _light={{ borderRight: '1px solid lightGray' }}
    >
      {children}
    </Box>
  );
}
