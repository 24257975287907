import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = config.definePartsStyle({
  field: {
    _disabled: {
      opacity: 0.8,
    },
    _light: { color: 'texas.bg.900' },
  },
  addon: {
    height: 'fit-content',
    bg: 'transparent',
    color: 'gray.200',
    _light: { color: 'gray.500' },
  },
  element: {
    color: 'gray.200',
    fontWeight: 'bold',
    _light: { color: 'gray.500' },
  },
});
export default config.defineMultiStyleConfig({
  defaultProps: { size: 'sm', variant: 'unstyled' },
  baseStyle,
});
