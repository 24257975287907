import {
  Tag,
  Textarea,
  TabList,
  Tab,
  Checkbox,
  Text,
  Flex,
  Box,
  PopoverTrigger,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  useToast,
  useColorMode,
} from '@chakra-ui/react';
import { useContext } from 'react';
import {
  SalesCurrencyEvaluation,
  findWorstProfit,
  profitColor,
} from '../shared';
import { useTranslation } from 'react-i18next';
import {
  useAutoUpdateForm,
  AutoUpdateWrapper,
} from '@texas/components/shared/autoUpdate/useAutoUpdateForm';
import { ReactSelectOption, ServerError } from '@texas/types';
import { SalesContext } from '../Sales';
import { SalesCurrencyTab } from '../SalesCurrencyTab';
import { SelectCurrencyDrawer } from '../SelectCurrency';
import { request } from '@texas/utils/helpers/httpHelpers';
import { Branch } from '@texas/api/endpoints/branchesApi';
import { CustomerCurrency } from '@texas/api/endpoints/currenciesApi';
import { PurchaseInformation } from '@texas/api/endpoints/purchaseInformationApi';
import {
  SalesInformation,
  AutoUpdateSalesInformationRequest,
  salesInformationApi,
} from '@texas/api/endpoints/salesInformationApi';
import { salesCurrenciesApi } from '@texas/api/endpoints/salesCurrenciesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { Icons } from '@texas/components/shared/Icons';
import { CopyValuePreview } from '@texas/components/shared/CopyValuePreview';
import { AutoGrowTextareaContainer } from '@texas/components/shared/form/AutoGrowTextareaContainer';
import { TexasInputWrapper } from '@texas/components/shared/TexasInputWrapper';
import { TexasSelect } from '@texas/components/shared/form/TexasSelect';

export interface SalesInformationFormProps {
  salesInformation: SalesInformation;
  mainPurchaseInformation: PurchaseInformation;
  branch: Branch;
  currencies: CustomerCurrency[];
  onCurrentCurrencyChanged: (currencyId: number) => void;
  selectedCurrency: CustomerCurrency;
  salesEvaluations: SalesCurrencyEvaluation[];
}

export function SalesInformationForm(props: SalesInformationFormProps) {
  const salesContext = useContext(SalesContext);
  const toast = useToast();
  const { t } = useTranslation();
  const { colorMode } = useColorMode();

  const addSalesCurrencyRequest = useApiRequest(
    salesCurrenciesApi.createSalesCurrency,
  );
  const {
    register,
    registerAutoUpdate,
    formState,
    setValue,
    watch,
    triggerSubmit,
    form,
  } = useAutoUpdateForm<AutoUpdateSalesInformationRequest, SalesInformation>({
    defaultValue: {
      isReady: props.salesInformation.isReady,
      syncPriceLists: props.salesInformation.syncPriceLists,
      notes: props.salesInformation.notes,
    },
    valueId: props.salesInformation.id,
    namePrefix: 'salesInformation',
    autoUpdateRequest: salesInformationApi.autoUpdateSalesInformations,
    onResponse: (data) => {
      const toSet = salesContext?.salesInformation.data?.map((s2) => {
        if (s2.id === props.salesInformation.id) {
          return { ...s2, ...data.mappedServerData };
        }

        return s2;
      });
      salesContext?.salesInformation.set(toSet ?? null);
    },
  });

  const salesNote = watch('notes');

  return (
    <form {...form}>
      <Box px={4} py={2}>
        <Flex pb={2} justifyContent="space-between">
          <Flex direction="column" alignItems="start" gap={2}>
            {props.mainPurchaseInformation.supplierId && (
              <Tag>
                {t('variant.sales.mainSupplier')} -
                {props.mainPurchaseInformation.supplierName} (
                {props.mainPurchaseInformation.supplierNumber})
              </Tag>
            )}
          </Flex>
          <Flex alignItems="center">
            <Text pr={2} variant="sub" whiteSpace="nowrap">
              {t('variant.sales.showCurrency')}
            </Text>
            <TexasSelect
              borderStyle="none"
              value={{
                label: props.selectedCurrency.code,
                value: props.selectedCurrency.id,
              }}
              w={24}
              size="sm"
              onChange={(e) => props.onCurrentCurrencyChanged(e?.value)}
              options={props.currencies.map<ReactSelectOption>((c) => ({
                label: c.code,
                value: c.id,
              }))}
            />
          </Flex>
        </Flex>
        <Flex direction="column" alignItems="start">
          <TexasInputWrapper error={formState.errors.notes} label="Note">
            <AutoUpdateWrapper
              autoUpdateProps={registerAutoUpdate}
              path="notes"
              boxProps={{ flexGrow: '1' }}
            >
              <AutoGrowTextareaContainer value={watch('notes')}>
                <Textarea
                  {...register('notes', {
                    maxLength: {
                      value: 4000,
                      message: t('errors.maxLength', { count: 4000 }),
                    },
                    onBlur: triggerSubmit,
                  })}
                  minHeight={10}
                  height="auto"
                  variant="unstyled"
                  placeholder="Notes"
                  padding={1}
                  width="100%"
                />
              </AutoGrowTextareaContainer>
            </AutoUpdateWrapper>
          </TexasInputWrapper>
          {props.mainPurchaseInformation.notes &&
            props.mainPurchaseInformation.notes !== salesNote && (
              <>
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <Button size="xs" leftIcon={<Icons.NoteAlert />}>
                      {t('variant.sales.showPurchaseNote')}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <CopyValuePreview
                        buttonText={t('variant.sales.copyPurchaseNote')}
                        value={props.mainPurchaseInformation.notes}
                        onClick={(value) => {
                          setValue('notes', value);
                          triggerSubmit();
                        }}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
            )}
        </Flex>
      </Box>

      <TabList alignItems="end">
        {props.salesInformation.currencies.map((s) => {
          const saleEvaluation = props.salesEvaluations.find(
            (e) => e.salesCurrencyId === s.id,
          );

          return (
            <Tab
              color={profitColor(
                findWorstProfit(saleEvaluation?.warnings ?? []),
                colorMode === 'dark',
              )}
              key={s.id}
              onClick={() => {
                props.onCurrentCurrencyChanged(s.currencyId);
              }}
            >
              <SalesCurrencyTab
                salesCurrency={s}
                warnings={saleEvaluation?.warnings}
              />
            </Tab>
          );
        })}
        <Flex alignItems="center" ml="auto" padding={2} gap={2}>
          <SelectCurrencyDrawer
            disabled={props.salesInformation.isReady}
            currencies={
              salesContext?.currencies.data?.filter(
                (c) =>
                  c.branchId === props.salesInformation.branchId &&
                  !props.salesInformation.currencies.some(
                    (c2) =>
                      c2.currencyCode.toLowerCase() === c.code.toLowerCase(),
                  ),
              ) ?? []
            }
            onCurrencyClick={(currency) => {
              performRequest(currency.id);
            }}
            mainSalesCurrency={props.salesInformation.currencies.find(
              (c) => c.isMain,
            )}
            branch={props.branch}
          />
          <AutoUpdateWrapper
            autoUpdateProps={registerAutoUpdate}
            path="isReady"
          >
            <Checkbox {...register('isReady', { onChange: triggerSubmit })}>
              <Text fontSize={12} fontWeight="bold">
                {t('variant.sync.readyForSync')}
              </Text>
            </Checkbox>
          </AutoUpdateWrapper>
          <AutoUpdateWrapper
            autoUpdateProps={registerAutoUpdate}
            path="syncPriceLists"
          >
            <Checkbox
              {...register('syncPriceLists', {
                onChange: triggerSubmit,
              })}
              isDisabled={
                props.salesInformation.priceListsSyncedOn ? true : false
              }
            >
              <Text fontSize={12} fontWeight="bold">
                {t('variant.sales.syncPriceLists')}
              </Text>
            </Checkbox>
          </AutoUpdateWrapper>
        </Flex>
      </TabList>
    </form>
  );

  async function performRequest(currencyId: number) {
    await request(
      addSalesCurrencyRequest.request,
      [props.salesInformation.id, { currencyId: currencyId }],
      (data) => {
        toast({
          title: t('variant.sales.currencyAdded'),
          status: 'success',
          isClosable: true,
        });

        const toSet =
          salesContext?.salesInformation.data?.map((s) => {
            if (s.id === props.salesInformation.id) {
              s.currencies.push(data);
            }

            return s;
          }) ?? [];

        salesContext?.salesInformation.set(toSet);
      },
      (error: ServerError) => {
        toast({
          title: 'Failed to add currency',
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );
  }
}
