import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SharedDisclosureProps } from './types';
import { useEffect, useState } from 'react';
import { ArticleState } from '@texas/api/endpoints/searchApi';
import { ErrorDetails } from './alert/ErrorDetails';
import { Icons } from './Icons';
import { ReactSelectOption, ServerError } from '@texas/types';
import {
  convertToEnum,
  getEnumNamesAndValues,
} from '@texas/utils/helpers/enumHelpers';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import {
  articlesApi,
  UpdateStateArticle,
  UpdateVariant,
} from '@texas/api/endpoints/articlesApi';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { blinkAnimation } from '@texas/resources/animations/animations';
import { TexasSelect } from './form/TexasSelect';
import { TexasFormLabel } from './form/TexasFormLabel';

export interface ChangeStateProps extends SharedDisclosureProps {
  onStateChanged: () => void;
  variants: UpdateVariant[] | null;
}

export function ChangeState(props: ChangeStateProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const {
    request: updateStateRequest,
    error,
    loading,
  } = useApiRequest(articlesApi.updateState);

  const [newState, setNewstate] = useState<ArticleState>();

  const {
    data: variants,
    refetch: refetchVariants,
    loading: variantsLoading,
    set: setVariants,
  } = useApiResource(articlesApi.getUpdateStateVariants);

  useEffect(() => {
    setNewstate(ArticleState.Registered);
    setVariants(null);
    if (!props.variants) return;
    refetchVariants({ variants: props.variants });
  }, [props.variants, refetchVariants, setVariants]);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('article.changeState')}</ModalHeader>
        <ModalBody>
          <ErrorDetails error={error} />
          <Flex dir="column" justify="center">
            {variantsLoading && <Spinner />}
            {!variantsLoading && (
              <TableContainer flexGrow={1}>
                <Table
                  size="md"
                  w="full"
                  maxHeight="380px"
                  overflowY="auto"
                  overflowX="hidden"
                  variant="striped"
                  colorScheme="gray"
                >
                  <Thead
                    pos="sticky"
                    top="0"
                    zIndex={1}
                    background="texas.bg.800"
                  >
                    <Tr>
                      <Th color="white">{t('general.itemCode')}</Th>
                      <Th color="white">{t('general.option')}</Th>
                      <Th color="white">{t('general.branch')}</Th>
                      <Th color="white">{t('general.state')}</Th>
                      <Th />
                      <Th color="white" fontWeight="bold">
                        {t('article.newState')}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {variants?.map((x, i) => {
                      return (
                        <Tr key={i}>
                          <Td>{x.itemCode}</Td>
                          <Td>{x.name}</Td>
                          <Td>{x.branchIdentifier}</Td>
                          <Td>{x.state}</Td>
                          <Td>
                            <Icons.ArrowRight />
                          </Td>
                          <Td
                            animation={blinkAnimation()}
                            color="texas.sand.100"
                            _light={{ color: 'texas.burntSienna.500' }}
                            fontWeight="bold"
                          >
                            {newState ? newState : ''}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Flex>
          <Flex my={4}>
            <FormControl>
              <TexasFormLabel>{t('article.newState')}</TexasFormLabel>
              <TexasSelect
                isClearable={true}
                value={getStateOption(newState)}
                onChange={(e) =>
                  setNewstate(convertToEnum(ArticleState, e?.value))
                }
                options={getEnumNamesAndValues(
                  ArticleState,
                ).map<ReactSelectOption>((x) => ({
                  label: x.name,
                  value: x.value,
                }))}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter
          backgroundColor="gray.800"
          _light={{ backgroundColor: 'gray.50' }}
        >
          <Button variant="texas-outline-light" onClick={props.onClose}>
            {t('general.close')}
          </Button>
          <Button
            variant="texas-solid"
            ml={3}
            isLoading={loading}
            isDisabled={loading || !newState}
            onClick={async () => {
              if (!newState) return;

              await request(
                updateStateRequest,
                [
                  {
                    variants: variants ?? [],
                    state: newState,
                  },
                ],
                (_: UpdateStateArticle[]) => {
                  toast({
                    title: t('general.updated'),
                    status: 'success',
                    isClosable: true,
                  });
                  props.onStateChanged();
                  props.onClose();
                },
                (error: ServerError) => {
                  toast({
                    title: t('general.updateFailed'),
                    description: error.message,
                    status: 'error',
                    isClosable: true,
                  });
                },
              );
            }}
          >
            {t('article.changeState')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function getStateOption(
  state: ArticleState | undefined,
): ReactSelectOption | null {
  if (!state) return null;

  const t = getEnumNamesAndValues(ArticleState);
  const v = t.find((x) => convertToEnum(ArticleState, x.value) === state)!;

  return { label: v.name, value: v.value };
}
