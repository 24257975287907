const buttonTheme = {
  baseStyle: {
    fontFamily: 'Barlow',
    borderRadius: '40px',
    _disabled: {
      opacity: 0.6,
    },
  },
  variants: {
    texas: {
      backgroundColor: 'texas.bg.900',
      _hover: {
        backgroundColor: 'texas.bg.700',
        color: 'white',
      },
      _light: {
        backgroundColor: 'texas.bg.900',
        color: 'white',
        _hover: {
          backgroundColor: 'texas.bg.700',
          _disabled: { backgroundColor: 'texas.bg.900' },
        },
      },
    },
    'texas-solid': {
      backgroundColor: 'texas.sand.100',
      color: 'white',
      _hover: {
        backgroundColor: 'texas.sand.50',
        _disabled: { backgroundColor: 'texas.sand.100' },
      },
      _light: {
        backgroundColor: 'texas.burntSienna.500',
        color: 'white',
        _hover: {
          backgroundColor: 'texas.burntSienna.100',
          _disabled: { backgroundColor: 'texas.burntSienna.500' },
        },
      },
    },
    'texas-light': {
      backgroundColor: 'whiteAlpha.200',
      color: 'white',
      _hover: {
        backgroundColor: 'whiteAlpha.300',
        _disabled: { backgroundColor: 'whiteAlpha.200' },
      },
      _light: {
        backgroundColor: 'blackAlpha.200',
        color: 'black',
        _hover: {
          backgroundColor: 'blackAlpha.300',
          _disabled: {
            backgroundColor: 'blackAlpha.200',
          },
        },
      },
    },
    'texas-outline': {
      backgroundColor: 'none',
      border: '2px solid',
      borderColor: 'texas.bg.800',
      color: 'white',
      _hover: {
        borderColor: 'texas.bg.700',
      },
      _light: {
        color: 'texas.burntSienna.500',
        borderColor: 'texas.burntSienna.500',
        _hover: {
          color: 'texas.burntSienna.100',
          borderColor: 'texas.burntSienna.100',
          backgroundColor: 'none',
        },
      },
    },
    'texas-outline-light': {
      backgroundColor: 'none',
      border: '2px solid',
      borderColor: 'whiteAlpha.200',
      color: 'whiteAlpha.800',
      _hover: {
        color: 'white',
        background: 'whiteAlpha.100',
      },
      _light: {
        color: 'texas.bg.700',
        borderColor: 'blackAlpha.200',
        _hover: {
          color: 'texas.bg.900',
          backgroundColor: 'blackAlpha.50',
        },
      },
    },
    pagination: {
      backgroundColor: 'none',
      border: '1px solid',
      borderColor: 'whiteAlpha.300',
      color: 'white',
      _hover: {
        background: 'whiteAlpha.200',
      },
      _active: { background: 'whiteAlpha.300' },
      _disabled: {
        opacity: 0.6,
        cursor: 'not-allowed',
      },
      _light: {
        color: 'black',
        borderColor: 'blackAlpha.100',
        _hover: {
          backgroundColor: 'blackAlpha.50',
        },
        _active: { background: 'blackAlpha.100' },
        _disabled: { _hover: { background: 'initial' } },
      },
    },
    'ghost-dashed': {
      border: '1px solid',
      borderColor: 'gray.600',
      borderStyle: 'dashed',
      color: 'gray.200',
      flexGrow: 1,
      padding: '16px',
      _hover: { cursor: 'pointer', backgroundColor: 'texas.bg.800' },
      _light: {
        color: 'gray.500',
        borderColor: 'gray.500',
        _hover: {
          color: 'gray.600',
          backgroundColor: 'gray.50',
        },
      },
    },
    'texas-link': {
      border: 'none',
      color: 'white',
      width: 'fit-content',
      padding: '0 8px 0 8px',
      backgroundColor: 'transparent',
      _hover: { color: 'whiteAlpha.600' },
      _light: {
        color: 'blackAlpha.700',
        _hover: { backgroundColor: 'transparent', color: 'blackAlpha.800' },
      },
    },
    'texas-link-danger': {
      textDecoration: 'underline',
      color: 'texas.danger.100',
      _light: { color: 'red.600' },
    },
  },
};

export default buttonTheme;
