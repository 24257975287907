import {
  Box,
  Flex,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Carousel, {
  CarouselInternalState,
  SkipCallbackOptions,
} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { TemplateView } from '../TemplateView';
import { CompositionGroupFooter } from './CompositionGroupFooter';
import { Icons } from '@texas/components/shared/Icons';
import { TemplateCard } from '../composition/TemplateCard';
import { range } from '@texas/utils/helpers/commonHelpers';
import {
  CompositionGroupComponentSettings,
  useCompositionGroup,
} from '../hooks/useCompositionGroup';
import { ConfirmCopy } from './ConfirmCopy';
import {
  CompositionGroup,
  compositionGroupApi,
} from '@texas/api/endpoints/compositionGroupApi';
import { SelectCellModal } from '../../matrix/SelectCellModal';
import { formatNodeGroup } from '@texas/utils/helpers/nodeGroupHelpers';
import { LoadingOverlayV2 } from '@texas/components/shared/LoadingOverlayV2';

interface Props {
  customTitle?: string;
  variantId: number;
  productGroupId: number;
  supplierId?: number;
  branchId?: number;
  cellX?: number;
  cellY?: number;
  articleNodeGroup?: ArticleNodeGroup;
  compositionGroupId?: number;
  onLoaded?: (template: CompositionGroup | null) => void;
  baseTemplateId: number | null;
  drawerRef?: RefObject<HTMLElement>;
  onUpdated?: () => void;
  canBeReplaced?: boolean;
  onlyArchived?: boolean;
  componentSettings?: CompositionGroupComponentSettings;
}

export const GroupContext = createContext<ReturnType<
  typeof useCompositionGroup
> | null>(null);

export function CompositionGroupView({
  customTitle,
  variantId,
  productGroupId,
  supplierId,
  branchId,
  cellX,
  cellY,
  articleNodeGroup,
  onLoaded,
  baseTemplateId,
  compositionGroupId,
  drawerRef,
  onUpdated,
  canBeReplaced,
  onlyArchived,
  componentSettings,
}: Props) {
  const { t } = useTranslation();

  const useGroup = useCompositionGroup({
    variantId,
    cellX,
    cellY,
    supplierId,
    branchId,
    baseTemplateId,
    compositionGroupId,
    canBeReplaced: canBeReplaced ?? true,
    articleNodeGroup,
    onUpdated,
    onlyArchived,
    componentSettings,
    onCompositionArchivedRestored: () => {
      if (visibleItems > 1) {
        setVisibleItems(useGroup.compositions().length - 1);
      }
    },
  });

  useEffect(() => {
    if (!onLoaded || !useGroup.group) return;
    onLoaded(useGroup.group);
  }, [onLoaded, useGroup.group]);

  const [visibleItems, setVisibleItems] = useState(1);

  return (
    <>
      <GroupContext.Provider value={useGroup}>
        <Flex flexDir="column" gap={2} w="fit-content" pos="relative">
          <Flex flexDir="column" mx="auto" textAlign="center">
            <Text>{customTitle}</Text>
            {useGroup.group?.exists ? (
              <Text fontSize="sm">
                {t('composition.version', { nr: useGroup.group.version })}
              </Text>
            ) : (
              <Text color="gray.300" fontSize="sm">
                {t('general.noData')}
              </Text>
            )}
          </Flex>

          <LoadingOverlayV2 loaded={!useGroup.loading} />
          {useGroup.group?.exists ? (
            <Box w={`${320 * visibleItems}px`} minH="400px" pos="relative">
              <Carousel
                customButtonGroup={
                  <CarouselFooter
                    expanded={visibleItems !== 1}
                    toggleView={() =>
                      setVisibleItems((r) =>
                        r === 1 ? useGroup.compositions().length : 1,
                      )
                    }
                    articleNodeGroup={articleNodeGroup}
                  />
                }
                partialVisible={true}
                arrows={false}
                containerClass="composition-carousel-list"
                responsive={{
                  desktop: {
                    breakpoint: { max: 4000, min: 0 },
                    items: visibleItems,
                    partialVisibilityGutter: 10,
                  },
                }}
              >
                {useGroup.compositions().length == 0 ? (
                  <TemplateCard exists={false} highlight={false}>
                    <Text m="auto" color="gray.400">
                      {t('composition.noComposition')}
                    </Text>
                  </TemplateCard>
                ) : (
                  useGroup.compositions().map((x) => {
                    return (
                      <TemplateView
                        imageIdentifier={useGroup.group!.imageIdentifier}
                        key={x.id}
                        drawerRef={drawerRef}
                        composition={x}
                        productGroupId={productGroupId}
                      />
                    );
                  })
                )}
              </Carousel>
            </Box>
          ) : (
            <>
              <TemplateCard exists={false} highlight={false}>
                <Text m="auto" color="gray.400">
                  {t('composition.noComposition')}
                </Text>
              </TemplateCard>
              <Menu placement="top">
                <MenuButton
                  as={IconButton}
                  variant="texas-light"
                  mt="auto"
                  mb={2}
                  icon={<Icons.DotsHorizontal />}
                />
                <MenuList>
                  <MenuItem onClick={useGroup.createGroupRequest}>
                    {t('composition.createEmpty')}
                  </MenuItem>
                  <MenuItem
                    isDisabled={!useGroup.canCopyFromTemplate}
                    onClick={() =>
                      useGroup.copyDisclosure.onOpen({
                        fromCompositionGroupId: useGroup.baseTemplateId!,
                        copyTitle: 'Copy variant base',
                      })
                    }
                  >
                    {t('composition.copyTemplateGroup')}
                  </MenuItem>
                  {useGroup.canCopyFromCells && (
                    <MenuItem
                      onClick={() => {
                        useGroup.selectCellDisclosure.onOpen();
                      }}
                    >
                      {t('composition.copyCellTemplateGroup')}
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </>
          )}
        </Flex>

        {useGroup.canBeReplaced && useGroup.copyDisclosure.value && (
          <ConfirmCopy
            title={useGroup.copyDisclosure.value.copyTitle}
            isOpen={useGroup.copyDisclosure.isOpen}
            onClose={useGroup.copyDisclosure.onClose}
            compositionGroupId={
              useGroup.copyDisclosure.value.fromCompositionGroupId
            }
            onConfirm={(id) => {
              useGroup.copyDisclosure.onClose();
              useGroup.selectCellDisclosure.onClose();
              useGroup.copyCompositionGroupRequest(id);
            }}
          />
        )}

        {useGroup.articleNodeGroup && (
          <SelectCellModal
            loadCellDataRequest={{
              request: compositionGroupApi.getAllCellGroups,
              requestData: [useGroup.locationData.current.variantId],
            }}
            selectedCell={(data, xIndex, yIndex) => {
              if (!data) return;
              useGroup.copyDisclosure.onOpen({
                fromCompositionGroupId: data.id,
                copyTitle: t('composition.fromCell', {
                  label: formatNodeGroup(
                    useGroup.articleNodeGroup!.nodeXValues[xIndex],
                    useGroup.articleNodeGroup!.nodeYValues[yIndex],
                  ),
                }),
              });
            }}
            articleNodeGroup={useGroup.articleNodeGroup}
            onClose={useGroup.selectCellDisclosure.onClose}
            isOpen={useGroup.selectCellDisclosure.isOpen}
          />
        )}
      </GroupContext.Provider>
    </>
  );
}

function CarouselFooter({
  articleNodeGroup,
  toggleView,
  expanded,
  carouselState,
  goToSlide,
}: {
  articleNodeGroup?: ArticleNodeGroup;
  carouselState?: CarouselInternalState;
  toggleView: () => void;
  expanded: boolean;
  goToSlide?: (slideIndex: number, skipCallbacks?: SkipCallbackOptions) => void;
}) {
  const { componentSettings } = useContext(GroupContext)!;

  if (!carouselState || !goToSlide) return null;

  return (
    <Flex justifyContent="end" w="full" align="center" gap={1} py={2}>
      {carouselState.totalItems > 1 &&
        componentSettings?.expander !== 'hidden' && (
          <IconButton
            icon={expanded ? <Icons.ArrowCollapse /> : <Icons.ArrowExpand />}
            aria-label="expand"
            onClick={() => {
              goToSlide(0);
              toggleView();
            }}
            variant="ghost"
          />
        )}

      <Flex flexWrap="wrap">
        {range(
          0,
          Math.ceil(carouselState.totalItems / carouselState.slidesToShow) - 1,
        ).map((x) => {
          const icon =
            carouselState.currentSlide == x ? (
              <Icons.Circle />
            ) : (
              <Icons.CircleOutline />
            );
          return (
            <IconButton
              size="sm"
              key={x}
              icon={icon}
              aria-label="slide-button"
              onClick={() => goToSlide(x)}
              variant="ghost"
            />
          );
        })}
      </Flex>
      {componentSettings?.compositionGroupDots !== 'hidden' && (
        <CompositionGroupFooter articleNodeGroup={articleNodeGroup} />
      )}
    </Flex>
  );
}
